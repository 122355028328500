import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { MdAdd, MdEdit } from "react-icons/md"
import ValidatorForm, { TextField, Switch } from "containers/ValidatorForm"
import Auxiliary from "util/Auxiliary"
import {
  getCategories,
  getSeriesData,
  upsertCategory,
} from "clientServices/audioStories"
import { Divider, Input } from "antd"
import Select from "containers/ValidatorForm/select"

export default function ManageCategories() {
  const [showForm, setShowForm] = useState(false)
  const [categories, setCategories] = useState([])
  const [seriesData, setSeriesData] = useState([])
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState("create")
  const [featuredAssets, setFeaturedAssets] = useState([])
  const [selectedFeaturedAssets, setSelectedFeaturedAssets] = useState(
    new Set()
  )

  const fetchCategories = async () => {
    let data = []
    try {
      setLoading(true)
      data = await getCategories()
    } catch (error) {
      toastr.error("Error in fetching categories...")
    } finally {
      setLoading(false)
    }
    return data
  }

  const fetchAllSeries = async () => {
    let data = []
    try {
      setLoading(true)
      data = await getSeriesData()
    } catch (error) {
      setLoading(false)
    }
    return data
  }

  useEffect(() => {
    ;(async () => {
      try {
        const [categoriesData, seriesData] = await Promise.all([
          fetchCategories(),
          fetchAllSeries(),
        ])

        setCategories(categoriesData)
        setSeriesData(seriesData)
      } catch (error) {
        console.error("Error fetching data:", error)
        // You can set error state here if needed
      }
    })()
  }, [])

  useEffect(() => {
    const selectedFeaturedAssets = featuredAssets.map(asset => asset.series_id)
    setSelectedFeaturedAssets(new Set(selectedFeaturedAssets))
  }, [featuredAssets])

  const handleSetFormData = data => {
    if (data.feature_assets) {
      const featuredAssets = data.feature_assets.map((asset, index) => ({
        view_order: index + 1,
        series_id: asset,
      }))
      setFeaturedAssets(featuredAssets)
    }
    delete data.feature_assets
    setFormData(data)
  }

  const onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    try {
      let featuredAssetsIds = []
      if (featuredAssets.length > 0) {
        featuredAssets.sort((a, b) => a.view_order - b.view_order)
        featuredAssetsIds = featuredAssets.map(asset => asset.series_id)

        formData.feature_assets = featuredAssetsIds
      }
      await upsertCategory(formData, action)
      const data = await fetchCategories()
      setCategories(data)
      setShowForm(false)
      setFormData({})
    } catch (error) {}
  }

  const handleAddFeaturedAsset = () => {
    setFeaturedAssets(prev => {
      const newData = [...prev]
      newData.push({
        series_id: "",
        view_order: "",
      })
      return newData
    })
  }

  const handleChangeFeaturedAssets = (value, key, assetIndex) => {
    setFeaturedAssets(prev => {
      const newData = [...prev]
      newData.forEach((data, index) => {
        if (index === assetIndex) {
          data[key] = value
        }
      })
      return newData
    })
  }

  const renderForm = () => {
    return (
      <Card>
        <CardTitle className="m-2">{`${action.toUpperCase()} CATEGORY`}</CardTitle>
        <CardBody>
          <ValidatorForm layout="vertical" onSubmit={onSubmit}>
            <Row>
              {formData.category_id && (
                <Col lg="6">
                  <TextField
                    label="Category ID"
                    placeholderLabel="Category ID"
                    defaultValue={formData.category_id}
                    field="category_id"
                    disabled
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col>
              )}
              <Col lg="6">
                <TextField
                  label="Display Name"
                  placeholderLabel="Display Name"
                  defaultValue={formData.display_name || ""}
                  field="display_name"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col lg="6">
                <TextField
                  inputType="number"
                  label="View Order"
                  placeholderLabel="View Order"
                  field="view_order"
                  defaultValue={formData.view_order || ""}
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col lg="6">
                <Select
                  label="Spotlight Asset"
                  options={seriesData.map(series => ({
                    label: series.title,
                    value: series.series_id,
                  }))}
                  placeholderLabel="Choose an asset"
                  field="spotlight_asset"
                  // validations={["required"]}
                  errors={["This field is required"]}
                  defaultValue={formData.spotlight_asset || ""}
                />
              </Col>
              <Col lg="6">
                <Select
                  label="Status"
                  options={[
                    { label: "Active", value: "active" },
                    { label: "In-Active", value: "inactive" },
                  ]}
                  placeholderLabel="Choose an asset"
                  field="status"
                  validations={["required"]}
                  errors={["This field is required"]}
                  defaultValue={formData.status || "inactive"}
                />
              </Col>
              <Col lg="3">
                <label>Is Pinned</label>
                <Switch
                  field="is_pinned"
                  className="creator-form"
                  defaultValue={formData.is_pinned}
                />
              </Col>
            </Row>
            <Divider>Featured Assets</Divider>
            {featuredAssets.length > 0 &&
              featuredAssets.map((asset, index) => {
                const options = seriesData.map(series => {
                  return {
                    label: series.title,
                    value: series.series_id,
                    disabled: selectedFeaturedAssets.has(series.series_id),
                  }
                })
                return (
                  <Row>
                    <Col lg="6">
                      <Select
                        options={options}
                        onChange={e =>
                          handleChangeFeaturedAssets(e, "series_id", index)
                        }
                        placeholderLabel="Choose an asset"
                        validations={["required"]}
                        errors={["This field is required"]}
                        value={asset.series_id}
                      />
                    </Col>
                    <Col lg="6">
                      <Input
                        type="number"
                        placeholder="View Order"
                        validations={["required"]}
                        errors={["This field is required"]}
                        onChange={e =>
                          handleChangeFeaturedAssets(
                            e.target.value,
                            "view_order",
                            index
                          )
                        }
                        value={asset.view_order}
                        size="large"
                      />
                    </Col>
                  </Row>
                )
              })}
            <Button variant="contained" onClick={handleAddFeaturedAsset}>
              Add More
            </Button>
            <Row gutter={16}>
              <Col span={24}>
                <div className="flex justify-content-end">
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  return (
    <Auxiliary loading={loading}>
      <div className="d-flex justify-content-end mb-2">
        <Button
          variant="contained"
          onClick={() => {
            setShowForm(true)
            setFormData({})
            setAction("create")
          }}
          startIcon={<MdAdd />}
        >
          Create
        </Button>
      </div>
      {showForm && renderForm()}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Display Name</b>
                </TableCell>
                <TableCell>
                  <b>Value</b>
                </TableCell>
                <TableCell>
                  <b>View Order</b>
                </TableCell>
                <TableCell>
                  <b>Status</b>
                </TableCell>
                <TableCell>
                  <b>Is Pinned</b>
                </TableCell>
                <TableCell>
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map(category => (
                <TableRow>
                  <TableCell>{category.display_name}</TableCell>
                  <TableCell>{category.key}</TableCell>
                  <TableCell>{category.view_order}</TableCell>
                  <TableCell>
                    {category.status === "active" ? "Active" : "In-Active"}
                  </TableCell>
                  <TableCell>{category.is_pinned ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setShowForm(true)
                        handleSetFormData(category)
                        setAction("update")
                      }}
                      size="large"
                    >
                      <MdEdit />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Auxiliary>
  )
}
