import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import watcherSaga from "../sagas/rootSaga"
import rootReducer from "../slices/rootReducer"

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
})

sagaMiddleware.run(watcherSaga)

export type RootState = ReturnType<typeof rootReducer>
export default store
