import React from "react"
import { withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Divider } from "antd"
import { Col, Row } from "reactstrap"

const AudioStoriesDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Story ID</h5>
          </Divider>
          <Col className="d-flex">
            <Input
              defaultValue={props.filters["storyId"]}
              placeholder={"Story Id"}
              onChange={e =>
                props.handleFilterChange(e.target.value.trim(), "storyId")
              }
              allowClear={true}
              name="storyId"
              style={{ width: "100%" }}
            />
          </Col>
        </Col>
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Series ID</h5>
          </Divider>
          <Col className="d-flex">
            <Input
              defaultValue={props.filters["seriesId"]}
              placeholder={"Series Id"}
              onChange={e =>
                props.handleFilterChange(e.target.value.trim(), "seriesId")
              }
              allowClear={true}
              name="seriesId"
              style={{ width: "100%" }}
            />
          </Col>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(AudioStoriesDrawer)
