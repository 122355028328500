import React, { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { connect, RootStateOrAny, useSelector } from "react-redux"
import Axios from "axios"
import QueryString from "query-string"
import _ from "lodash"
import { Col, Container, Row, Table } from "reactstrap"
import { Tooltip } from "antd"
import {
  diamondsByUsers,
  getLiveCallingUserData,
  totaldurationbyuseruuid,
  getStatusByUsers,
} from "clientServices/liveCallingService"
import CallingCard from "./CallingCard"
import { Button, Chip, Stack } from "@mui/material"
import { RouteComponentProps, withRouter } from "react-router-dom"
import Chips from "components/CommonFilter/chips"
import { Pagination } from "antd"
import type { PaginationProps } from "antd"
import Auxiliary from "util/Auxiliary"

interface Props extends RouteComponentProps {
  queryParams: { [key: string]: string | number }
  loading: boolean
}
type Filter = {
  pageSize: string | number
  currentPage: string | number
  platformNotExist: string
  agency_id: string
}

interface Creator {
  user_uuid: string
  totaldiamond?: number // Optional because it's added later
  [key: string]: any // For other dynamic properties
}

interface StatusByUseruuid
{
  [key: string] : any
}

function BrowseCreators(props: Props){
  const urlQueryParams = new URLSearchParams(location.search)

  const initialFilter: { [key: string]: string } = {};

  for (const [key, value] of urlQueryParams.entries()) {
    initialFilter[String(key)] = String(value)
  }

  const initialCurrentPage = parseInt(urlQueryParams.get("currentPage") ?? "1")
  const initialPageSize = parseInt(urlQueryParams.get("pageSize") ?? "40")

  if (!urlQueryParams.get("currentPage")) {
    urlQueryParams.set("currentPage", "1")
  }

  if (!urlQueryParams.get("pageSize")) {
    urlQueryParams.set("pageSize", "40")
    props.history.push(`${location.pathname}?${urlQueryParams.toString()}`)
  }


  const [filters, setFilters] = useState<Filter>({
    pageSize: initialPageSize,
    currentPage: initialCurrentPage,
    platformNotExist: "Y",
    agency_id: "",
    ...initialFilter
  })

  const [showFilter, setShowFilter] = useState(false)

  const [loading, setLoading] = useState(true)

  const [totalItems, setTotalItems] = useState(0)

  const [env, setEnv] = useState("")
  const [userData, setUserData] = useState<Creator[]>([])

  useEffect(() => {
    fetchAndSetUserData(filters)
  }, [])

  const {
    currentUser: { data },
  } = useSelector((state:RootStateOrAny) => state.currentUser)

  const fetchAndSetUserData = async (filterValues: Filter) => {
    setLoading(true)
    const res = await getLiveCallingUserData({ filters: filterValues })
    
    const listOfUsers = res.data
      ?.filter((item: any) => item.user_uuid != null)
      .map((items: any) => items.user_uuid)

    const totalDurationCountByUseruuid = await totaldurationbyuseruuid({
      user_uuids: listOfUsers,
    })

    let statusByUseruuid: StatusByUseruuid = {}
    const userPermissions = _.values(data.jotPermissions)
    if(userPermissions.includes("EDIT_AUDIO_CALL_STATUS")){
      const chunkSize = 10;
      for (let i = 0; i < listOfUsers.length; i += chunkSize) {
          const chunk = listOfUsers.slice(i, i + chunkSize);
          const statusByUseruuidbatch = await getStatusByUsers(listOfUsers)
          Object.entries(statusByUseruuidbatch).map(([key, value], index) => (
            statusByUseruuid[key]=value
          ))
      }
    }
    console.log(statusByUseruuid,listOfUsers, totalDurationCountByUseruuid)

    const updatedUserData = res.data.map((user: Creator) => ({
      ...user,
      totalDuration: totalDurationCountByUseruuid[user.user_uuid] || 0,
      status: statusByUseruuid[user.user_uuid]?.status || ""
    }))

    setTotalItems(res.total)
    setEnv(res.user_env)
    setUserData(updatedUserData)
    setLoading(false)
  }

  const handleLoading = () => {
    setLoading(true)
  }
  const onCreate = () => {
    props.history.push(`/live/creators/manage`)
  }
  const onEdit = (itemId: string) => {
    props.history.push(`/live/creators/manage?creator_id=` + itemId)
  }

  const onInfo = (itemInfo: string) => {
    props.history.push(`/live/creators/info?creator_uuid=` + itemInfo)
  }
  const _closeFilters = async () => {
    await fetchAndSetUserData(filters)
    updateURLParams(filters)
    setShowFilter(false)
  }
  // _removeFilter = filter => {
  //   const newFilters = {
  //     ...this.state.filters,
  //     [filter]: audioCallingFilters[filter].defaultValue,
  //   }

  //   this.setState(
  //     {
  //       filters: newFilters,
  //     },
  //     async () => {
  //       let storiesData = await getStoriesData()
  //       this.setState({
  //         storiesData,
  //       })
  //     }
  //   )
  // }

  const _removeFilter = (filterKey: keyof Filter) => {
    const newFilters = _.cloneDeep(filters)
    newFilters["currentPage"] = 1 // Reset page to 1
    _.unset(newFilters, filterKey)
    setFilters(newFilters)
    const queryParams = new URLSearchParams(props.history.location.search)
    queryParams.set("currentPage", "1")
    props.history.push(`${location.pathname}?${queryParams.toString()}`)
    fetchAndSetUserData(newFilters)
    updateURLParams(newFilters)
  }

  const _handleFilterChange = (value: string, type: keyof Filter) => {
    const queryParams = new URLSearchParams(props.history.location.search)
    queryParams.set("currentPage", "1")
    props.history.push(`${location.pathname}?${queryParams.toString()}`)
    const newFilters = _.cloneDeep(filters)
    newFilters[type] = value
    newFilters["currentPage"] = 1
    setFilters(newFilters)
  }

  const _toggleFilters = () => {
    setShowFilter(!showFilter)
  }

  const handlePageChange: PaginationProps["onChange"] = async page => {
    const newFilters = { ...filters, currentPage: page }
    setFilters(newFilters)
    const queryParams = new URLSearchParams(props.history.location.search)
    queryParams.set("currentPage", String(page))
    props.history.push(`${location.pathname}?${queryParams.toString()}`)
    await fetchAndSetUserData(newFilters)
  }

  const updateURLParams = (newFilters: Filter) => {
    const queryParams = new URLSearchParams()
    
    // Set pagination values
    queryParams.set("currentPage", String(newFilters.currentPage))
    queryParams.set("pageSize", String(newFilters.pageSize))
    
    
    // Add all the other active filters to the URL
    Object.keys(newFilters).forEach((key) => {
      const value = newFilters[key as keyof Filter]
      if (value) {
        queryParams.set(key, String(value))
      } else {
        queryParams.delete(key) // Remove from URL if filter is unset
      }
    })
    props.history.push(`${location.pathname}?${queryParams.toString()}`)
  }
  
  return (
    <Auxiliary loading={loading}>
      <div>
        <Row>
          <Chips
            showFilters={showFilter}
            filters={filters}
            search={_closeFilters}
            removeFilter={_removeFilter}
            handleFilterChange={_handleFilterChange}
            type="audio_live_calling"
            toggleFilters={_toggleFilters}
          />
        </Row>
        <Row>
          <div
            className="d-flex justify-content-end"
            style={{ marginBottom: "10px" }}
          >
            <Button
              onClick={onCreate}
              role="button"
              color="success"
              variant="contained"
            >
              Create
            </Button>
          </div>
          <br />
          {userData.map((item: any) => (
            <Col md="2" style={{width:"18%",margin: "0 10px"}}>
              <CallingCard
                env={env}
                item={item}
                onInfo={onInfo}
                onEdit={onEdit}
                handleLoading={handleLoading}
              ></CallingCard>
            </Col>
          ))}{" "}
        </Row>

        <div className="d-flex justify-content-end pb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={handlePageChange}
            current={Number(filters.currentPage)}
            total={totalItems}
            pageSize={Number(filters.pageSize)}
            showSizeChanger={false}
          />
        </div>
      </div>
    </Auxiliary>
  )
}

export default withRouter(BrowseCreators)
