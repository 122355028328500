import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { resetState } from "appRedux/slices/zone"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Form, Input, Select } from "antd"
import { Card, CardBody, Row, Col } from "reactstrap"
import { LANGUAGES } from "constants/uiConstants"
import { upsertRating } from "clientServices/ratingService"
import toastr from "toastr"
import { Button } from "@mui/material"

const { Option } = Select

class UpsertRating extends ErrorBoundComponent {
  state = {
    showInterestForm: false,
    formData: {
      issue_type: "",
      issue_rank: "",
      issue_description: "",
      user_type: "",
      status: "ACTIVE",
    },
    errors: {},
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      window.addEventListener("keydown", this._keyDownHandler)
    }
    if (this.props.currentRow) {
      this.setState({
        formData: {
          ...this.props.currentRow,
        },
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value

    if (field === "issue_description") {
      if (value.length > 44) {
        toastr.clear()
        toastr.error("Description can't be more than 44 characters...")
        return
      }
    }

    this.setState(
      {
        formData,
        errors: {
          ...this.state.errors,
          [field]: null,
        },
      },
      () => {
        console.log(this.state.errors)
      }
    )
  }

  _handlePageConfigFieldChange = (field, index, value) => {
    let formData = this.state.formData
    formData[field][index] = value

    this.setState(
      {
        formData,
        errors: {
          ...this.state.errors,
          [field]: null,
        },
      },
      () => {
        console.log(this.state.errors)
      }
    )
  }

  formatOptionLabel = ({ value, title, zone_image }) => {
    return (
      <span>
        {zone_image ? (
          <img
            src={zone_image}
            alt={title}
            style={{ hight: "20px", width: "20px" }}
          />
        ) : (
          <i className="fa fa-users" aria-hidden="true"></i>
        )}{" "}
        &nbsp;
        <b>{title}</b>
      </span>
    )
  }
  _onVideoRemove = () => {
    this.setState({
      csvFileList: [],
      csvFile: null,
    })
  }

  _onSubmit = async () => {
    if (
      this.state.formData?.issue_type.trim() == "" ||
      this.state.formData?.issue_rank.trim() == "" ||
      this.state.formData?.user_type.trim() == "" ||
      this.state.formData?.issue_description.trim() == "" ||
      this.state.formData?.status.trim() == ""
    ) {
      toastr.clear()
      toastr.error("All fields are mandatory")
      return
    }
    let data = { ...this.state.formData, action: this.props.action }
    try {
      let result = await upsertRating(data)
    } catch (error) {
    } finally {
      this.props.toggleCreateForm()
    }
  }

  _iscreateRatingForm = () => {
    this.props.toggleCreateForm()
  }

  _uploadCSV = fileObj => {
    if (fileObj.file) {
      this.setState({
        csvFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        csvFile: fileObj.file,
      })
    }
  }
  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          <Card>
            <CardBody>
              <Form layout="vertical" autoComplete="false">
                <Row className="mb-3">
                  <Col lg="4">
                    <Form.Item label="Issue Type">
                      <Select
                        showSearch
                        size="large"
                        allowClear={true}
                        defaultValue={
                          this.props?.currentRow?.issue_type ||
                          this.state.formData.issue_type
                        }
                        onChange={e => this._handleFieldChange("issue_type", e)}
                        style={{ width: "100%" }}
                      >
                        <Option value="CAMERA">Camera</Option>
                        <Option value="JOSH_ECONOMY">Josh Economy</Option>
                        <Option value="MODERATION">Moderation</Option>
                        <Option value="ACCOUNT">Account</Option>
                        <Option value="LIVE">Live</Option>
                        <Option value="TECH_ISSUES">Tech Issues</Option>
                        <Option value="VIDEOS">Videos</Option>
                        <Option value="FEATURES">Features</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg="4">
                    <Form.Item label="User Type">
                      <Select
                        showSearch
                        size="large"
                        allowClear={true}
                        // placeholder="Include Languages"
                        defaultValue={
                          this.props?.currentRow?.user_type ||
                          this.state.formData.user_type
                        }
                        onChange={e => this._handleFieldChange("user_type", e)}
                        style={{ width: "100%" }}
                      >
                        <Option value="ALL">All</Option>
                        <Option value="L">L</Option>
                        <Option value="M">M</Option>
                        <Option value="H">H</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg="4">
                    <Form.Item label="Issue Rank">
                      <Select
                        showSearch
                        size="large"
                        allowClear={true}
                        // placeholder="Include Languages"
                        defaultValue={
                          this.props?.currentRow?.issue_rank ||
                          this.state.formData.issue_rank
                        }
                        onChange={e => this._handleFieldChange("issue_rank", e)}
                        style={{ width: "100%" }}
                      >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg="3">
                    <Form.Item label="Status">
                      <Select
                        showSearch
                        size="large"
                        allowClear={true}
                        // placeholder="Include Languages"
                        defaultValue={
                          this.props?.currentRow?.status ||
                          this.state.formData.status
                        }
                        onChange={e => this._handleFieldChange("status", e)}
                        style={{ width: "100%" }}
                        disabled={this.props.action === "update"}
                      >
                        <Option value={"ACTIVE"}>{"ACTIVE"}</Option>
                        <Option value={"DISABLED"}>{"DISABLED"}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg="9">
                    <Form.Item label="Issue Description">
                      <Input
                        type="text"
                        placeholder="Add Issue Description.."
                        onChange={e =>
                          this._handleFieldChange(
                            "issue_description",
                            e.target.value
                          )
                        }
                        defaultValue={this.state.formData.issue_description}
                        value={this.state.formData.issue_description}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Row>
                <div className="d-flex justify-content-end">
                  <Button
                    htmlType="submit"
                    variant="contained"
                    color="success"
                    disabled={this.state.loading}
                    onClick={() => {
                      this._onSubmit()
                    }}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    disabled={this.state.loading}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                    onClick={() => this._iscreateRatingForm()}
                    className="mx-2"
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
          {/* <CreateCreateThemeficationFormForm /> */}
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    allZoneList: _.get(store, "zone.allZoneList"),
  }
}

export default connect(mapStateToProps)(UpsertRating)
