import React from "react"
import { message, Tooltip } from "antd"
import _ from "lodash"
import { Button } from "@mui/material"
import { connect } from "react-redux"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import ValidatorForm, { TextField, Select, Switch } from "../ValidatorForm"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import "./styles.css"
import {
  getSeriesData,
  getStoriesData,
  upsertStoryCollection,
} from "clientServices/audioStories"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getImage } from "../../components/SearchAndSelect/util"
import { DeleteOutlined, CopyOutlined } from "@ant-design/icons"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import Auxiliary from "util/Auxiliary"
import toastr from "toastr"
require("moment-duration-format")

class AudioStoryCollectionForm extends ErrorBoundComponent {
  state = {
    fileList: [],
    isSponsored: false,
    formData: {},
    items: [],
  }
  constructor(props) {
    super(props)
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  onChange = key => {
    console.log(key)
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      try {
        this.setState({
          loading: true,
        })
        let seriesData = await getSeriesData()
        let { data: storiesData } = await getStoriesData({
          filters: { pageSize: 10000 },
        })

        const items =
          this.props.audio?.elements?.sort(
            (a, b) => a.view_order - b.view_order
          ) || []

        this.setState({ items, allData: [...seriesData, ...storiesData] })
      } catch (error) {
        toastr.error("Error in fetching data...")
      } finally {
        this.setState({
          loading: false,
        })
      }
    }
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    let finalResult = result.map((list, index) => {
      return { ...list, ...{ view_order: index + 1 } }
    })
    return finalResult
  }
  onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const items = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    )

    this.setState({
      items,
    })
  }

  delete = (id, view_order) => {
    let new_items = []
    for (let i = 0; i < this.state.items.length; i++) {
      if (id != undefined && this.state.items[i].id == id) {
      } else if (
        id == undefined &&
        this.state.items[i].view_order == view_order
      ) {
      } else {
        new_items.push(this.state.items[i])
      }
    }
    let finalResult = new_items.map((list, index) => {
      return { ...list, ...{ view_order: index + 1 } }
    })
    this.setState({
      items: finalResult,
    })
  }
  _getCardData = item => {
    let collectionEntity = {}
    this.state.allData?.forEach(entity => {
      if (item.type == "series" && entity.series_id === item.id) {
        collectionEntity = { ...entity, ...item }
      } else if (item.type == "story" && entity.story_id === item.id) {
        collectionEntity = { ...entity, ...item }
      }
    })

    return (
      <Row className="align-items-center">
        <Col span={2}>
          {collectionEntity.cover_url ||
          collectionEntity.thumbnail ||
          collectionEntity.profile_pic ||
          collectionEntity.static_thumbnail ||
          collectionEntity.thumbnail_url ||
          collectionEntity.zone_image ||
          collectionEntity.banner_url ? (
            <img
              src={
                collectionEntity.cover_url ||
                collectionEntity.thumbnail ||
                collectionEntity.profile_pic ||
                collectionEntity.static_thumbnail ||
                collectionEntity.banner_url ||
                collectionEntity.thumbnail_url ||
                collectionEntity.zone_image
              }
              style={{ width: "50px", height: "50px" }}
            />
          ) : (
            <FontAwesomeIcon
              className="card-img-top-custom"
              style={{
                width: "50px",
                height: "50px",
                color: "darkgrey",
                padding: "10px",
                margin: "auto",
              }}
              icon={getImage(collectionEntity)}
            />
          )}
        </Col>
        <Col span={4}>
          <div>
            {collectionEntity.title ||
              collectionEntity.name ||
              collectionEntity.hashtag ||
              collectionEntity.content_title}
          </div>
          <div>{collectionEntity.type}</div>
          <div>View Order: {collectionEntity.view_order}</div>
        </Col>
        <Col>
          <div className="d-flex">
            <Button
              style={{ color: "blue", borderColor: "blue", marginLeft: "5px" }}
              onClick={() => {
                this.delete(collectionEntity.id, item.view_order)
              }}
            >
              <DeleteOutlined />
            </Button>
            <CopyToClipboard
              text={collectionEntity.id}
              onCopy={() => {
                message.success("Copied sucessfully!")
              }}
            >
              <Tooltip title="Copy Entity ID">
                <Button
                  style={{
                    color: "blue",
                    borderColor: "blue",
                    marginLeft: "5px",
                  }}
                >
                  <CopyOutlined />
                </Button>
              </Tooltip>
            </CopyToClipboard>
          </div>
        </Col>
      </Row>
    )
  }

  _form = () => {
    return (
      <>
        <ValidatorForm
          onSubmit={this._onSubmit}
          layout={"vertical"}
          {...this._formLayout}
        >
          <Row>
            {this.props.formAction === "update" && (
              <Col sm="12" md="6">
                <TextField
                  label={"Collection Id"}
                  placeholderLabel="Collection Id"
                  field={"collection_id"}
                  className="creator-form"
                  defaultValue={
                    this.props.formAction === "update"
                      ? this.props.audio.collection_id
                      : ""
                  }
                  disabled={true}
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
            )}
            <Col sm="12" md="6">
              <TextField
                label={"Title"}
                placeholderLabel="Title"
                field={"title"}
                className="creator-form"
                defaultValue={
                  this.props.formAction === "update"
                    ? this.props.audio.title
                    : ""
                }
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
            <Col sm="12" md="6">
              <Select
                label={"Type"}
                placeholderLabel="Typee"
                field={"type"}
                className="creator-form"
                defaultValue={
                  this.props.formAction === "update"
                    ? this.props.audio.type
                    : ""
                }
                validations={["required"]}
                errors={["This field is required"]}
                options={[
                  { value: "spotlight", label: "SpotLight" },
                  { value: "normal", label: "Normal" },
                  { value: "continue_listening", label: "Continue Listening" },
                ]}
              />
            </Col>
            <Col sm="12" md="6">
              <TextField
                label={"View Order"}
                placeholderLabel="View Order"
                field={"view_order"}
                className="creator-form"
                allowClear={true}
                inputType={"Number"}
                defaultValue={
                  this.props.formAction === "update"
                    ? this.props.audio.view_order
                    : " "
                }
                style={{
                  width: "100%",
                }}
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
            <Col sm={12} md={6}>
              <label> Status</label>
              <Switch
                defaultValue={
                  this.props.audio.status == "active" ? true : false
                }
                field={"status"}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                this.setState({
                  selectAudioSeriesModal: true,
                })
              }
              className="mx-2"
            >
              Add Series
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                this.setState({
                  selectAudioStoryModal: true,
                })
              }
            >
              Add Story
            </Button>
          </div>
          <Row style={{ marginTop: "10px" }}>
            <Col md="12">
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {this.state.items?.map((item, index) => {
                        return (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}-id`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                style={provided.draggableProps.style}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  style={{
                                    border: "1px solid #E5E5E5",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    backgroundColor: "white",
                                    width: "100%",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {this._getCardData(item)}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Col>
          </Row>

          <div className="d-flex justify-content-end pb-2 pt-2">
            <Button
              variant="contained"
              type="submit"
              disabled={this.state.actionLoading}
              onDoubleClick={e => {
                e.preventDefault()
                e.stopPropagation()
                return
              }}
            >
              {this.props.formAction === "create" ? "Create" : "Update"}
            </Button>
            <Button
              variant="contained"
              color="error"
              disabled={this.state.actionLoading}
              onDoubleClick={e => {
                e.preventDefault()
                e.stopPropagation()
                return
              }}
              onClick={() => this.props.onCancel()}
              className="mx-2"
            >
              Cancel
            </Button>
          </div>
        </ValidatorForm>

        {this.state.selectAudioStoryModal && (
          <SearchAndSelect
            type="AUDIO_STORY"
            visible={this.state.selectAudioStoryModal}
            onClose={() => this.setState({ selectAudioStoryModal: false })}
            onAdd={arr => {
              let finalItem = []
              let length = this.state.items.length

              for (let i = 0; i < arr.length; i++) {
                if (this.state.items.length > 0) {
                  for (let j = 0; j < this.state.items.length; j++) {
                    if (this.state.items[j].id == arr[i]) {
                      finalItem.push(this.state.items[j])
                      break
                    }
                    if (j == this.state.items.length - 1) {
                      finalItem.push({
                        id: arr[i],
                        type: "story",
                        view_order: length + 1,
                      })
                      length++
                    }
                  }
                } else {
                  finalItem.push({
                    id: arr[i],
                    type: "story",
                    view_order: length + 1,
                  })
                  length++
                }
              }

              this.setState({ items: finalItem })
            }}
            selectedArr={_.uniq(this.state.items?.map(a => a.id))}
            zoneLevel={0}
            allZoneList={this.state.allData}
          />
        )}

        {this.state.selectAudioSeriesModal && (
          <SearchAndSelect
            type="AUDIO_SERIES"
            visible={this.state.selectAudioSeriesModal}
            onClose={() => this.setState({ selectAudioSeriesModal: false })}
            onAdd={arr => {
              let finalItem = []
              let length = this.state.items.length

              for (let i = 0; i < arr.length; i++) {
                if (this.state.items.length > 0) {
                  for (let j = 0; j < this.state.items.length; j++) {
                    if (this.state.items[j].id == arr[i]) {
                      finalItem.push(this.state.items[j])
                      break
                    }
                    if (j == this.state.items.length - 1) {
                      finalItem.push({
                        id: arr[i],
                        type: "series",
                        view_order: length + 1,
                      })
                      length++
                    }
                  }
                } else {
                  finalItem.push({
                    id: arr[i],
                    type: "series",
                    view_order: length + 1,
                  })
                  length++
                }
              }

              this.setState({ items: finalItem })
            }}
            selectedArr={_.uniq(this.state.items?.map(a => a.id))}
            zoneLevel={0}
            allZoneList={this.state.allData}
          />
        )}
      </>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    try {
      if (errors) {
        return
      }
      formData.status == true
        ? (formData.status = "active")
        : (formData.status = "inactive")
      formData.elements = this.state.items

      await upsertStoryCollection(formData)
      this.props.fetchAudioStories()
    } catch (err) {}
  }

  _render = () => {
    return (
      <Auxiliary loading={this.state.loading}>
        <Card>
          <CardBody>
            <CardTitle>Create / Update Collection</CardTitle>
            {this._form()}
          </CardBody>
        </Card>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(AudioStoryCollectionForm)
