import { createSlice } from "@reduxjs/toolkit"

const locationSlice = createSlice({
  name: "location",
  initialState: {
    locationList: [],
    loading: false,
  },
  reducers: {
    getLocationData(state) {
      return { ...state, loading: true, locationList: [] }
    },
    getLocationDataSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        locationList: payload.data,
      }
    },
    resetState(state) {
      return {
        locationList: [],
        loading: false,
      }
    },
  },
})

export const { getLocationData, getLocationDataSuccess, resetState } =
  locationSlice.actions

export default locationSlice.reducer
