import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button } from "@mui/material"
import { MdAdd } from "react-icons/md"
import { getCategories, getSeriesData } from "clientServices/audioStories"
import AudioSeriesForm from "./audioSeriesForm"
import { sleep } from "util/common"
import SeriesCard from "./seriesCard"
import { NO_PERMISSION_MESSAGE, seriesFilters } from "constants/uiConstants"
import toastr from "toastr"
import Chips from "components/CommonFilter/chips"
import NoDataFound from "components/NoDataFound/noDataFound"
import { agencyList } from "clientServices/liveCallingService"

class ManageSeries extends ErrorBoundComponent {
  state = {
    showSeriesForm: false,
    seriesData: [],
    currentSeries: undefined,
    filters: {
      seriesId: "",
    },
    agencyLists: [],
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      try {
        this.setState({ loading: true })
        let seriesData = await getSeriesData()
        let categoryData = await getCategories()
        let groupedSeriesData = _.groupBy(seriesData, "parent_series_id")
        let res = await agencyList({ type: "AUDIO_STORY" })
        let modAgency = res?.data?.map(item => {
          return { label: item.name, value: item.uuid }
        })

        this.setState({
          seriesData,
          groupedSeriesData,
          categoryData,
          agencyLists: modAgency,
        })
      } catch (error) {
        toastr.error("Error in fetching data...")
      } finally {
        this.setState({
          loading: false,
        })
      }
    }
  }

  fetchAudioStories = async () => {
    await sleep(1000)
    this.setState({
      loading: true,
    })
    let seriesData = await getSeriesData()
    this.setState({
      seriesData,
      showSeriesForm: false,
      loading: false,
    })
  }

  onEditClick = (action, data) => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_AUDIO_SERIES")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({
      currentSeries: data,
      formAction: action,
      showSeriesForm: true,
    })
  }

  _onAddClick = () => {
    this.setState({
      currentSeries: [],
      formAction: "create",
      showSeriesForm: true,
    })
  }

  onCancel = () => {
    this.setState({
      currentSeries: undefined,
      formAction: undefined,
      showSeriesForm: false,
    })
  }

  _searchStories = async () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.setState({
      loading: true,
    })

    let seriesData = await getSeriesData(_.deepClean(this.state.filters))
    this.setState({
      seriesData,
      loading: false,
    })
  }
  _closeFilters = () => {
    this._searchStories()
    this.setState({
      showAdvancedFilter: false,
      showFilters: false,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: seriesFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      async () => {
        let seriesData = await getSeriesData()
        this.setState({
          seriesData,
        })
      }
    )
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading || this.state.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          {this.state.showSeriesForm && (
            <AudioSeriesForm
              formAction={this.state.formAction}
              onCancel={this.onCancel}
              audioSeries={this.state.currentSeries}
              groupedSeriesData={this.state.groupedSeriesData}
              fetchAudioStories={this.fetchAudioStories}
              categoryData={this.state.categoryData ?? []}
              agencyLists={this.state.agencyLists}
            ></AudioSeriesForm>
          )}

          {!this.state.showSeriesForm && (
            <>
              <Chips
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFilterChange}
                type="audio_series"
                toggleFilters={this._toggleFilters}
              />
              <Row>
                <Col
                  lg="12"
                  md="12"
                  className="d-flex justify-content-end mb-3"
                >
                  <Button
                    startIcon={<MdAdd />}
                    variant="contained"
                    onClick={this._onAddClick}
                  >
                    create
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(256px, 1fr))",
                      gridGap: "2rem",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.state.seriesData?.map(item => {
                      if (item.series_id === item.parent_series_id) {
                        return (
                          <SeriesCard
                            series={item}
                            onEditClick={this.onEditClick}
                          ></SeriesCard>
                        )
                      }
                    })}
                  </div>
                  {!this.state.seriesData && (
                    <NoDataFound message="No data available" />
                  )}
                </Col>
              </Row>
            </>
          )}
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageSeries)
