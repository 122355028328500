import PropTypes from "prop-types"
import React, { useState, createRef, useEffect } from "react"
import { Card, Row, CardBody, Col, UncontrolledTooltip } from "reactstrap"
import { Button } from "@mui/material"
import { formatNumber, msToHMS } from "../../util/common"
import { CURATED_TYPE_MAPPING } from "constants/uiConstants"
import { FaCopy, FaShare, FaShareAlt } from "react-icons/fa"
import { AiTwotoneHeart, AiFillEye } from "react-icons/ai"
import { Chip, Stack } from "@mui/material"
import "./style.css"
import { Tooltip, Tag, Modal } from "antd"
import toastr from "toastr"
import CopyToClipboard from "react-copy-to-clipboard"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { updateStatus } from "clientServices/liveCallingService"
import { IoDiamondSharp } from "react-icons/io5";
import { MdEdit, MdOutlineAccessTimeFilled } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { RootStateOrAny,useSelector } from "react-redux"
import _ from "lodash"

interface Props extends RouteComponentProps {
  item: {
    user_uuid: string
    profile_pic: string
    user_name: string
    status: string
    user_media:Array<UserMedia>;
    live_call_enabled: Boolean
    avg_rating:number
    totalDuration:number
    totaldiamond:{
      total_diamonds_count:number
    }
  },
  env:string

  onInfo: (e: string) => void

  onEdit: (e: string) => void
  handleLoading: () => void
}
type UserMedia={
    type:string,
    url:string
}
const _renderThumbnail = (props: Props) => {
  return (
    <img
      src={props.item.user_media?.filter(items=> items.type == "image")[0]?.url}
      width="100%"
      height={"355px"}
      alt=""
      style={{ filter: "blur(.5px)", opacity: "0.8", cursor: "pointer" }}
    />
  )
}

const CallingCard = (props: Props) => {
  const [showAddVideosPopup, setShowAddVideosPopup] = useState<any>(false)
  const _getModerationImage = (props: Props) => {
    return props.item.profile_pic
  }
  const manageStatus = async (live_call_enabled: Boolean) => {
    await updateStatus({ live_call_enabled, user_uuid: props.item.user_uuid })
    props.handleLoading()
    setShowAddVideosPopup(false)
  }
  const handleModal = (status: Boolean) => {
    setShowAddVideosPopup(status)
  }
  const _renderStatusModal = () => {
    return (
      <Modal
        width={700}
        title="Update Status"
        open={showAddVideosPopup}
        footer={null}
        onCancel={() => {
          setShowAddVideosPopup(false)
        }}
        destroyOnClose
      >
        <div>{`Do you want to ${
          props.item.live_call_enabled == false ? "enable" : "disable"
        } the creator from receiving audio calls?`}</div>
        <div className="d-flex justify-content-end mt-2">
          <Button
            onClick={() => {
              manageStatus(props.item.live_call_enabled == false ? true : false)
            }}
            variant="contained"
            className="mx-1"
            color="primary"
            size="small"

            // size="small"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              handleModal(false)
            }}
            variant="contained"
            className="mx-1"
            color="primary"
            size="small"

            // size="small"
          >
            Cancel
          </Button>
        </div>
      </Modal>
    )
  }
  const {
    currentUser: { data },
  } = useSelector((state:RootStateOrAny) => state.currentUser)
  const userPermissions = _.values(data.jotPermissions)
  return (
    <div>
      <Card
        className={`text-center shadow-lg`}
        style={{ borderRadius: "10px" }}
      >
        <Row>
          <Col className="align-self-end" style={{ width: "100%" }}>
            {_renderThumbnail(props)}
            {
              <div style={{ position: "absolute", top: "10px", left: "20px" }}>
                <div style={{ display: "flex" }}>
                  <img
                    src={_getModerationImage(props)}
                    className="rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                 
                </div>
              </div>
            }
           
          </Col>
        </Row>

        <CardBody style={{ padding: ".5rem", fontSize: "18px" }}>
        <Tooltip title={props?.item?.user_name}>
            <div className="font-size-14 text-truncate font-weight-semibold">
              {props?.item?.user_name}
            </div>
          </Tooltip>
          <b>
            <Chip
              color="primary"
              variant="outlined"
              size="small"
              label={
                props?.item?.live_call_enabled
                  ? "Active"
                  : "InActive"
              }
            />
          </b>
          {userPermissions.includes("EDIT_AUDIO_CALL_STATUS") ? (
            <b>
              <Tooltip title={"Status"}>
                <Chip
                  color="primary"
                  variant="outlined"
                  size="small"
                  className="m-2"
                  label={props?.item?.status}
                />
              </Tooltip>
            </b>
          ) : null}
          <div className="d-flex justify-content-around align-items-center pt-3">
          {
              <div
                style={{ position: "static", bottom: "22px",height: "54px", width: "92%" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  {" "}
                 
                  <div style={{}}>
                    <MdOutlineAccessTimeFilled
                      style={{
                        padding: "2px",
                        fontSize: "24px",
                        color: "black",
                      }}
                    />
                    <div style={{
                        
                        fontSize: "15px",
                       
                      }}>{props?.item?.totalDuration ? msToHMS(props?.item?.totalDuration) :'-'}</div>
                 
                  </div>
                  <div>
                    <FaStar
                      style={{
                        padding: "2px",
                        fontSize: "24px",
                        color: "black",
                      }}
                    />
                    <div style={{
                        
                        fontSize: "15px",
                       
                      }}>{props?.item?.avg_rating}</div>
                  </div>
                </div>
              </div>
            }  
          </div>
          <div className="d-flex justify-content-around align-items-center pt-2">
            <div
              className="d-flex flex-column align-items-center"
              onClick={() => {
                props.onInfo(props.item.user_uuid)
              }}
            >
              <AiFillEye
                style={{ cursor:'pointer',padding: "2px", fontSize: "24px", color: "black" }}
              />
            </div>
            <div
              className="d-flex flex-column align-items-center"
              
            >
              <CopyToClipboard
                text={props.item.user_uuid}
                onCopy={() => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip  title="Copy User ID">
                  <FaCopy style={{cursor:'pointer'}} />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div
              className="d-flex flex-column align-items-center"
              onClick={
                () => {
                    props.onEdit(props.item.user_uuid)
                  }
              }
            >
              <div className="flex-fill">
                <Tooltip  title="Edit">
                 <MdEdit style={{cursor:'pointer'}} />
                </Tooltip>
              </div>
            </div>
            <div
              className="d-flex flex-column align-items-center"
              onClick={() => {}}
            >
              <CopyToClipboard
                text={`${props.env}/profile/${props.item.user_uuid}`}
                onCopy={() => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip  title="Copy Share URl">
                <FaShareAlt style={{cursor:'pointer'}} />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div
              className="d-flex flex-column align-items-center"
             
            >
              <div className="flex-fill">
                {props.item.live_call_enabled === false ? (
                  <Tooltip title="Activate">
                    {" "}
                    <i
                      className="bx bx-comment-error"
                      role="button"
                      style={{ color: "green" }}
                      onClick={() => handleModal(true)}
                    />{" "}
                  </Tooltip>
                ) : (
                  <Tooltip title="Deactivate">
                    <i
                      className="bx bx-block"
                      role="button"
                      style={{ color: "red" }}
                      onClick={() => handleModal(true)}
                    />{" "}
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {_renderStatusModal()}
    </div>
  )
}

export default withRouter(CallingCard)
