import {
  downloadInvoice,
  getAgencyCreators,
  getAgencyInvoice,
  getAgencyStats,
  setAgencyCreators,
  setAgencyInvoices,
  setAgencyStats,
  setInvoice,
} from "appRedux/slices/agency"
import { fetchError } from "appRedux/slices/common"
import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestAgencyCreators,
  requestAgencyInvoice,
  requestAgencyStats,
  requestDownloadInvoice,
} from "../requests/agency"

function* handleGetAgencyStats({ payload }) {
  try {
    const { data } = yield call(requestAgencyStats, payload)
    yield put(setAgencyStats(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAgencyCreators({ payload }) {
  try {
    const { data } = yield call(requestAgencyCreators, payload)
    yield put(setAgencyCreators(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAgencyInvoice({ payload }) {
  try {
    const { data } = yield call(requestAgencyInvoice, payload)
    yield put(setAgencyInvoices(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDownloadInvoice({ payload }) {
  try {
    const { data } = yield call(requestDownloadInvoice, payload)
    yield put(setInvoice(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getAgencyStats.type, handleGetAgencyStats)
  yield takeLatest(getAgencyCreators.type, handleGetAgencyCreators)
  yield takeLatest(getAgencyInvoice.type, handleGetAgencyInvoice)
  yield takeLatest(downloadInvoice.type, handleDownloadInvoice)
}
