import Axios from "axios"
import toastr from "toastr"

export const requestGifts = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"]
  try {
    let data = await Axios.post(
      `/api/gift/entity/list?type=${payload.filters.type}&offset=${offset}&limit=${limit}`,
      { filters: payload.filters }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertGift = async ({ payload }) => {
  try {
    const url = `/api/gift/entity/upsert?action=${payload.action}`
    let response = await Axios.post(url, payload.data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertGiftCollection = async ({ payload }) => {
  try {
    const url = `/api/gift/collection/upsert?action=${payload.action}`
    let response = await Axios.post(url, payload.data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestReorderGiftCollectionEntity = async ({ payload }) => {
  try {
    const url = `/api/gift/collection/entity/reorder`
    let response = await Axios.post(url, payload)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertGiftCollectionEntity = async ({ payload }) => {
  try {
    const url = `/api/gift/collection/entity`
    let response = await Axios.post(url, payload)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestDeleteGifts = async ({ payload }) => {
  try {
    const url = `/api/gift/delete`
    let response = await Axios.post(url, payload)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpgradeGiftListVersion = async () => {
  try {
    let response = await Axios.get(`/api/gift/upgradeVersion`)
    toastr.success("Version Updated Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}
export const requestUpgradeGiftListVersion2 = async () => {
  try {
    let response = await Axios.get(`/api/gift/upgradeVersion2`)
    toastr.success("Version 2 Updated Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}
