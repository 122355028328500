import React from "react"
import _ from "lodash"
import { Button } from "@mui/material"
import { connect } from "react-redux"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import ChipInput from "material-ui-chip-input"
import ValidatorForm, { TextField, Switch, TextArea } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import ColorPicker from "../../components/ColorPicker/colorPicker"
import { upsertStory } from "clientServices/audioStories"
require("moment-duration-format")
import "./styles.css"
import { getSeriesData } from "../../clientServices/audioStories"
import Select from "../ValidatorForm/select"
import { Form } from "antd"

class AudioStoryForm extends ErrorBoundComponent {
  state = {
    fileList: {},
    isSponsored: false,
    formData: {},
    reciters: this.props.audio.reciters || [],
    seriesData: [],
  }

  async componentDidMount() {
    try {
      let seriesData = await getSeriesData()
      this.setState({ seriesData })
    } catch (error) {}
  }

  _uploadAlbumArt = fileObj => {
    if (fileObj.file) {
      this.setState({
        albumArtFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        albumArt: fileObj.file,
      })
    }
  }

  _uploadAudio = fileObj => {
    if (fileObj.file) {
      this.setState({
        audioFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        audioFile: fileObj.file,
      })
    }
  }

  _onAudioRemove = () => {
    this.setState({
      audioFileList: [],
      audioFile: null,
    })
  }

  _handleMultiSelect = value => {
    let formData = this.state.formData
    formData["playlists_info"] = value
    this.setState({ formData })
  }

  _handleFileUpload = (key, file) => {
    this.setState({
      fileList: {
        ...this.state.fileList,
        [key]: file,
      },
    })
  }

  addChip = value => {
    const feed_hashtags = this.state?.reciters || []
    feed_hashtags.push(value)
    this.setState({
      reciters: feed_hashtags,
    })
  }

  removeChip = (val, index) => {
    const feed_hashtags = this.state?.reciters || []
    feed_hashtags.splice(index, 1)
    this.setState({
      reciters: feed_hashtags,
    })
    console.log(val, index)
  }

  _form = () => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          {this.props.formAction === "update" && (
            <>
              {" "}
              <Col sm="12" md="6">
                <TextField
                  label={"Id"}
                  placeholderLabel="Id"
                  field={"story_id"}
                  className="creator-form"
                  defaultValue={
                    this.props.formAction === "update"
                      ? this.props.audio.story_id
                      : ""
                  }
                  disabled={true}
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm="12" md="6"></Col>
            </>
          )}

          <Col sm="12" md="6">
            <TextField
              label={"Title"}
              placeholderLabel="Title"
              field={"track_title"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update" ? this.props.audio.title : ""
              }
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>

          <Col sm="12" md="6">
            <TextField
              label={"Description"}
              placeholderLabel="Description"
              field={"description"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.description
                  : ""
              }
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <Select
              label={"Series Name"}
              placeholderLabel="Series Name"
              field={"series_id"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.series_id
                  : ""
              }
              options={this.state.seriesData.map(item => ({
                label: item.title,
                value: item.series_id,
              }))}
              disabled={this.props.formAction === "update" ? true : false}
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Story Number"}
              placeholderLabel="Story Number"
              field={"story_number"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.story_number
                  : ""
              }
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Price"}
              placeholderLabel="Price"
              field={"price"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.price
                  : ""
              }
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Discounted Price"}
              placeholderLabel="Discounted Price"
              field={"discounted_price"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.discounted_price
                  : ""
              }
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Verse Margin"}
              placeholderLabel="Verse Margin"
              field={"verse_margin"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.verse_margin
                  : ""
              }
            />
          </Col>
          <Col sm="12" md="6">
            <Select
              label="Source"
              name="source"
              field={"source"}
              size="large"
              options={this.props.agencyLists}
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.partner_id
                  : this.props.agencyLists[0].value
              }
            />
          </Col>
          <Col sm="12" md="6" lg={6}>
            <label className="me-1" htmlFor="radioVertical">
              Reciters
            </label>
            <br></br>
            <ChipInput
              value={this.state?.reciters || []}
              onAdd={chip => this.addChip(chip)}
              onDelete={(chip, index) => this.removeChip(chip, index)}
              // disabled
            />
          </Col>

          <Col sm="12" md="6">
            <TextField
              label={"Cover Url"}
              placeholderLabel="Cover Url"
              field={"cover_url"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.cover_url
                  : ""
              }
            />
          </Col>
          <Col sm="12" md="6">
            <TextArea
              label={"Stream Url"}
              placeholderLabel="Stream Url"
              field={"stream_url"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio.stream_url
                  : ""
              }
            />
          </Col>
          <Col lg="6" className="d-flex">
            <div style={{ width: "100%", marginRight: "10px" }}>
              <label className="col-form-label">Cover File</label>
              <input
                type="file"
                className="form-control"
                // accept=".mp3,audio/*"
                onChange={e => {
                  this._handleFileUpload("cover_file", e.target.files[0])
                }}
              />
            </div>
            <div>
              <img
                style={{ height: "75px" }}
                src={this.props.audio.cover_url}
              />
            </div>
          </Col>
          <Col lg="6" className="d-flex">
            <div style={{ width: "100%" }}>
              <label className="col-form-label">Stream File</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  this._handleFileUpload("audio_file", e.target.files[0])
                }}
              />
            </div>
          </Col>

          <Col sm="12" md="6" className="mt-2">
            <ColorPicker
              label="BG Color"
              placeholderLabel="BG Color"
              field="bg_color"
              defaultColor={this.props.audio?.bg_color || "#5154b2"}
            />
          </Col>
          <Col sm={12} md={6} className="d-flex justify-content-between mt-3">
            <div>
              <label>Status</label>
              <Switch
                defaultValue={
                  this.props.audio.status == "active" ? true : false
                }
                field={"status"}
              />
            </div>
            <div>
              <label>Independent Story</label>
              <Switch
                defaultValue={
                  this.props.audio.is_independent_story === true ? true : false
                }
                field={"is_independent_story"}
              />
            </div>
            <div>
              <label>Is Paid</label>
              <Switch
                defaultValue={
                  this.props.audio.is_premium === true ? true : false
                }
                field={"is_paid"}
              />
            </div>
          </Col>
        </Row>

        <div className="d-flex justify-content-end pb-2 pt-2">
          <Button
            variant="contained"
            type="submit"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            {this.props.formAction === "create" ? "Create" : "Update"}
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
            onClick={() => this.props.onCancel()}
            className="mx-2"
          >
            Cancel
          </Button>
        </div>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    try {
      if (errors) {
        return
      }
      let data = new FormData()
      formData.reciters = this.state.reciters
      Object.keys(this.state.fileList).forEach(fileName => {
        data.append(fileName, this.state.fileList[fileName])
      })

      formData.status == true
        ? (formData.status = "active")
        : (formData.status = "inactive")

      data.append("details", JSON.stringify(formData))
      let updateAudioStory = await upsertStory(data)
      this.props.fetchAudioStories()
    } catch (err) {}
  }

  _render = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>Create / Update Audio Story</CardTitle>
          {this._form()}
        </CardBody>
      </Card>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(AudioStoryForm)
