import React, { Component } from "react"
import {
  Input,
  InputNumber,
  Typography,
  Select,
  Switch,
  Divider,
  Form,
} from "antd"
import { Button } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import { Col, Row } from "reactstrap"
import "./DSLCss.css"
class DynamicForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formValues: {},
      showKeyValuePairForm: {},
      validations: {},
    }
  }

  //   handleInputChange = (fieldName, value) => {
  //     this.setState(prevState => ({
  //       formValues: { ...prevState.formValues, [fieldName]: value }
  //     }));
  //   };
  handleInputChange = (fieldName, value) => {
    this.props.onFormValuesChange(fieldName, value)
  }

  handleObjectFieldChange = (fieldName, value) => {
    if (this.state.newKey?.trim() && this.state.newValue?.trim()) {
      this.props.onFormValuesChange(fieldName, value, "object", "add")
    }
  }

  handleObjectDeleteFieldChange = (fieldName, subField) => {
    this.props.onFormValuesChange(fieldName, subField, "object", "delete")
  }

  toNumber = value => {
    return Number(value)
  }

  renderNestedObjectFields = (parentFieldName, nestedKey, nestedObject) => {
    return (
      <div>
        {Object.entries(nestedObject).map(([key, value], index) => (
          <div key={index}>
            <label>{key}</label>
            {typeof value === "object" ? (
              this.renderNestedObjectFields(
                `${parentFieldName}.${nestedKey}`,
                key,
                value
              )
            ) : (
              <input
                type="text"
                value={value}
                onChange={e =>
                  this.handleNestedObjectFieldChange(
                    parentFieldName,
                    nestedKey,
                    key,
                    e.target.value
                  )
                }
              />
            )}
            <IconButton
              onClick={() =>
                this.handleDeleteNestedObjectField(
                  parentFieldName,
                  nestedKey,
                  key
                )
              }
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
            <button
              onClick={() =>
                this.handleDeleteNestedObjectField(
                  parentFieldName,
                  nestedKey,
                  key
                )
              }
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    )
  }

  renderInputField = field => {
    switch (field.type) {
      case "boolean":
        return (
          <Col lg="6" md="8" className="my-1">
            <Typography.Title level={5}> {field.label}</Typography.Title>
            <Switch
              key={field.field_name}
              checked={field.value}
              onChange={e => this.handleInputChange(field.field_name, e)}
              disabled={field.editable !== undefined ? !field.editable : false}
            />
          </Col>
        )
      case "string":
        return (
          <Col lg="6" md="8" className="my-1">
            <Typography.Title level={5}>
              {" "}
              {field.label}{" "}
              {field?.validations?.required ? (
                <span class="required-field"></span>
              ) : (
                <></>
              )}{" "}
            </Typography.Title>
            <Input
              key={field.field_name}
              type="text"
              onChange={e => {
                this.handleInputChange(field.field_name, e.target.value)
              }}
              style={field.error_message ? { borderColor: "red" } : {}}
              placeholder="Enter"
              defaultValue={field.value || ""}
              disabled={field.editable !== undefined ? !field.editable : false}
            />

            {field.error_message && (
              <div
                style={{
                  color: "red",
                  fontSize: "14px",
                }}
              >
                {field.error_message}
              </div>
            )}
          </Col>
        )
      case "number":
        return (
          <Col lg="6" md="8" className="my-1">
            <Typography.Title level={5}>
              {" "}
              {field.label}
              {field?.validations?.required ? (
                <span class="required-field"></span>
              ) : (
                <></>
              )}
            </Typography.Title>
            <Input
              key={field.field_name}
              type="number"
              style={field.error_message ? { borderColor: "red" } : {}}
              onChange={e =>
                this.handleInputChange(field.field_name, e.target.value)
              }
              placeholder="Enter"
              defaultValue={field.value || ""}
              disabled={field.editable !== undefined ? !field.editable : false}
            />
            {field.error_message && (
              <div
                style={{
                  color: "red",
                  fontSize: "14px",
                }}
              >
                {field.error_message}
              </div>
            )}
          </Col>
        )
      case "array":
        switch (field.mode) {
          case "single_select":
            return (
              <Col lg="6" md="8" className="my-1">
                <Typography.Title level={5}>
                  {" "}
                  {field.label}{" "}
                  {field?.validations?.required ? (
                    <span class="required-field"></span>
                  ) : (
                    <></>
                  )}
                </Typography.Title>
                <Select
                  key={field.field_name}
                  size={"middle"}
                  style={
                    field.error_message
                      ? { border: "1px solid red", width: "100%" }
                      : { width: "100%" }
                  }
                  placeholder="Please select"
                  defaultValue={field.value || []}
                  options={field.options || []}
                  onChange={e => {
                    this.handleInputChange(
                      field.field_name,
                      field.format == "number" ? e.map(this.toNumber) : e
                    )
                  }}
                  disabled={
                    field.editable !== undefined ? !field.editable : false
                  }
                />
                {field.error_message && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                    }}
                  >
                    {field.error_message}
                  </div>
                )}
              </Col>
            )
          case "multi_select":
            return (
              <Col lg="6" md="8" className="my-1">
                <Typography.Title level={5}>
                  {" "}
                  {field.label}{" "}
                  {field?.validations?.required ? (
                    <span class="required-field"></span>
                  ) : (
                    <></>
                  )}
                </Typography.Title>
                <Select
                  key={field.field_name}
                  mode="tags"
                  style={
                    field.error_message
                      ? { border: "1px solid red", width: "100%" }
                      : { width: "100%" }
                  }
                  size={"middle"}
                  placeholder="Please select"
                  defaultValue={field.value || []}
                  onChange={e => {
                    // let numarr=  e.map(this.toNumber)
                    this.handleInputChange(
                      field.field_name,
                      field.format == "number" ? e.map(this.toNumber) : e
                    )
                  }}
                  options={field.options || []}
                  disabled={
                    field.editable !== undefined ? !field.editable : false
                  }
                />
                {field.error_message && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                    }}
                  >
                    {field.error_message}
                  </div>
                )}
              </Col>
            )
        }

      case "header":
        return (
          <Col lg="12" className="my-1">
            <Divider dashed>
              <h4>{field.label || field.field_name}</h4>
            </Divider>
          </Col>
        )
      case "object":
        return (
          <Col lg="6" md="8" className="my-1">
            <Typography.Title level={5}> {field.label}</Typography.Title>
            {field.value &&
              Object.keys(field.value).map((subField, index) => (
                <div key={index}>
                  <Row>
                    <Col
                      md="3"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      &nbsp; {subField}
                    </Col>
                    <Col
                      md="2"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      <InputNumber
                        type="number"
                        onChange={e =>
                          this.handleObjectFieldChange(field.field_name, {
                            [subField]: e,
                          })
                        }
                        key={field.field_name}
                        style={{ marginLeft: "5px" }}
                        value={field.value[subField]}
                        //   defaultValue={field.value[subField] || ""}
                      />
                    </Col>
                    <Col md="1">
                      <IconButton
                        onClick={() =>
                          this.handleObjectDeleteFieldChange(
                            field.field_name,
                            subField
                          )
                        }
                        style={{
                          color: "#ff4f4f",
                        }}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Col>
                  </Row>
                </div>
              ))}
            {this.state.showKeyValuePairForm[field.field_name] && (
              <div>
                <label>Key:</label>
                <Input
                  type="text"
                  onChange={e => this.setState({ newKey: e.target.value })}
                  style={{ width: "17%", marginLeft: "5px" }}
                  defaultValue={this.state.newKey || ""}
                />
                &nbsp; <label>Value:</label>
                <Input
                  type="text"
                  onChange={e => this.setState({ newValue: e.target.value })}
                  style={{ width: "16%", marginLeft: "5px" }}
                  defaultValue={this.state.newValue || ""}
                />
                <Button
                  variant="contained"
                  className="mx-1"
                  color="success"
                  size="small"
                  onClick={() => {
                    this.handleObjectFieldChange(field.field_name, {
                      [this.state.newKey]: this.state.newValue,
                    })
                    this.setState(prevState => ({
                      showKeyValuePairForm: {
                        ...prevState.showKeyValuePairForm,
                        [field.field_name]: false,
                      },
                      newValue: "",
                      newKey: "",
                    }))
                  }}
                >
                  Add
                </Button>
                <Button
                  variant="contained"
                  className="mx-1"
                  color="error"
                  size="small"
                  onClick={() =>
                    this.setState(prevState => ({
                      showKeyValuePairForm: {
                        ...prevState.showKeyValuePairForm,
                        [field.field_name]: false,
                      },
                      newValue: "",
                      newKey: "",
                    }))
                  }
                >
                  Cancel
                </Button>
              </div>
            )}
            {!this.state.showKeyValuePairForm[field.field_name] && (
              <Button
                variant="contained"
                className="mx-1"
                size="small"
                onClick={() =>
                  this.setState(prevState => ({
                    showKeyValuePairForm: {
                      ...prevState.showKeyValuePairForm,
                      [field.field_name]: true,
                    },
                  }))
                }
              >
                Add
              </Button>
            )}
          </Col>
        )
      default:
        return null
    }
  }

  render() {
    const { formData } = this.props

    return (
      <div className="dynamic-form">
        <h3>{formData?.label}</h3>
        <hr />
        <Row>
          {formData?.layout.map((field, index) => (
            // <div style={{ marginBottom: "10px" }} key={index}>
            <> {this.renderInputField(field)}</>
            // </div>
          ))}
        </Row>
      </div>
    )
  }
}

export default DynamicForm
