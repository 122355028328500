import { all } from "redux-saga/effects"
// import ConfigSaga from "./config.saga"
import CurrentUserSaga from "./handlers/currentUser"
import CMSUserSaga from "./handlers/cmsUser"
import CommentSaga from "./handlers/comment"
import ContentSaga from "./handlers/content"
import AppUserSaga from "./handlers/appUser"
// import HandshakeSaga from "./handshake.saga"
import AudioSaga from "./handlers/audio"
import ChallengeSaga from "./handlers/challenge"
import ZeroSearchSaga from "./handlers/zeroSearch"
import GiftSaga from "./handlers/gift"
import BadgeSaga from "./handlers/badge"
import PackageSaga from "./handlers/package"
import LocationSaga from "./handlers/location"
import uiConstantsSaga from "./handlers/uiConstants"
// import SectionSaga from "./section.saga"
// import SearchOrderSaga from "./searchOrder.saga"
import HashtagSaga from "./handlers/hashtag"
import ViewActivitySaga from "./handlers/activity"
import CameraAssetSaga from "./handlers/cameraAsset"
import CreatorIssues from "./handlers/creatorIssues"
// import SimilaritySaga from "./similarity.saga"
//import VideoAssetsSaga from "./videoAssets.saga"
import CMSVideoAssetsSaga from "./handlers/cmsVideoAssets"
import CMSVideoTemplatesSaga from "./handlers/cmsVideoTemplates"
import StickersSaga from "./handlers/stickers"
import LayoutSaga from "./layout.saga"
import CommunitySaga from "./handlers/community"
import TrendingSaga from "./handlers/trending"
import SourceSaga from "./handlers/source"
import ContestSaga from "./handlers/contest"
import CampaignSaga from "./handlers/campaign"
import SponsoredTagsSaga from "./handlers/sponsoredTags"
import VideoAssetsSaga from "./handlers/videoAssets"
import JoshliveSaga from "./handlers/joshlive"
import ReviewSaga from "./handlers/review"
import IssueCommentsSaga from "./handlers/issueComments"
import EventSaga from "./handlers/events"
import IconsSaga from "./handlers/icons"
import ImageSaga from "./handlers/image"
import ZoneSaga from "./handlers/zone"
import SocialSaga from "./handlers/social"
import TemplateSectionSaga from "./handlers/templateSection"
import MemeSaga from "./handlers/meme"
import AdsSaga from "./handlers/ads"
import LimitSaga from "./handlers/limit"
import CtaSaga from "./handlers/cta"
import ListingsSaga from "./handlers/listings"
import KycSaga from "./handlers/kyc"
import DynamicFormSaga from "./handlers/dynamicForm"
import AgencyStatsSaga from "./handlers/agency"

export default function* watcherSaga(getState) {
  yield all([
    // ConfigSaga(),
    CurrentUserSaga(),
    CMSUserSaga(),
    CommentSaga(),
    ContentSaga(),
    AppUserSaga(),
    AudioSaga(),
    ChallengeSaga(),
    ZeroSearchSaga(),
    GiftSaga(),
    BadgeSaga(),
    LocationSaga(),
    // SectionSaga(),
    // SearchOrderSaga(),
    HashtagSaga(),
    ViewActivitySaga(),
    CameraAssetSaga(),
    CreatorIssues(),
    // SimilaritySaga(),
    VideoAssetsSaga(),
    CMSVideoAssetsSaga(),
    CMSVideoTemplatesSaga(),
    StickersSaga(),
    LayoutSaga(),
    CommunitySaga(),
    TrendingSaga(),
    SourceSaga(),
    ContestSaga(),
    CampaignSaga(),
    SponsoredTagsSaga(),
    uiConstantsSaga(),
    JoshliveSaga(),
    ReviewSaga(),
    IssueCommentsSaga(),
    EventSaga(),
    IconsSaga(),
    PackageSaga(),
    ImageSaga(),
    ZoneSaga(),
    SocialSaga(),
    TemplateSectionSaga(),
    MemeSaga(),
    AdsSaga(),
    LimitSaga(),
    CtaSaga(),
    ListingsSaga(),
    KycSaga(),
    DynamicFormSaga(),
    AgencyStatsSaga(),
  ])
}
