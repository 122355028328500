import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { List, Select } from "antd"
import { Button } from "@mui/material"
import { withRouter } from "react-router-dom"
import { getDynamicForms } from "appRedux/slices/dynamicForm"
import { MdModeEdit } from "react-icons/md"

const { Option } = Select
class DSLList extends ErrorBoundComponent {
  state = {
    data: [],
    filter: {
      sort: "desc",
      mode: "",
      type: "",
      metric: "absolute",
    },
    subInterestStatusList: {},
  }
  constructor(props) {
    super(props)
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      //   this.setState({
      //     loading: true,
      //   })
      this.props.dispatch(getDynamicForms())
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  render() {
    return (
      <Auxiliary
        loading={this.state.loading || this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <br />
        <Row>
          <Col lg="6" md="12">
            <h4>DSL Experiment List</h4>
          </Col>
        </Row>
        {/* <Divider orientation="left">My Watches</Divider> */}

        <>
          <List
            style={{ width: "100%", margin: "15px 0px" }}
            grid={{ gutter: 16, column: 2 }}
            bordered
            dataSource={
              this.props.dynamicFormData?.data &&
              this.props.dynamicFormData?.data.length > 0 &&
              this.props.dynamicFormData?.data
            }
            renderItem={(item, index) => (
              <>
                <List.Item>
                  <Row className="p-2">
                    {" "}
                    <Col lg="8" md="8">
                      <b>{item.name}</b> <br></br>
                    </Col>
                    <Button
                      onClick={() => {
                        this.props.history.push(`/dsl/edit?name=${item.name}`)
                      }}
                      style={{
                        width: "15%",
                      }}
                      variant="contained"
                      startIcon={<MdModeEdit />}
                      size="small"
                    >
                      Edit
                    </Button>{" "}
                  </Row>
                </List.Item>
              </>
            )}
          />
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "dynamicForm.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    dynamicFormData: _.get(store, "dynamicForm.dynamicFormData"),
  }
}

export default withRouter(connect(mapStateToProps)(DSLList))
