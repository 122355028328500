import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Divider, Upload } from "antd"
import toastr from "toastr"
import _ from "lodash"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm, { Switch } from "../ValidatorForm"
import { registerPackage } from "clientServices/packageService"

export default class PackageForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      title: this.props?.data?.title || "",
      orig_price: this.props?.data?.orig_price || null,
      discount_percentage: this.props?.data?.discount_percentage ?? null,
      conversion_factor: this.props?.data?.conversion_factor || null,
      discounted_price: this.props?.data?.discounted_price || null,
      jems_count: this.props?.data?.jems_count || null,
      package_url: this.props?.data?.package_url || null,
      tag_title: this.props?.data?.tag?.title || null,
      tag_colour: this.props?.data?.tag?.colour || null,
      currency: this.props?.data?.currency || null,
      default_currency: this.props?.data?.default_currency || null,
      product_info: this.props?.data?.product_info,
      hide_jems_package: this.props?.data?.hide_jems_package,
    }
  }

  componentDidMount() {
    let android_sku_id = null
    let ios_sku_id = null
    let android_lite_sku_id = null

    this.state?.product_info?.forEach(item => {
      if (item.platform === "android") {
        android_sku_id = item.product_id
      }
      else if (item.platform === "android_lite") {
        android_lite_sku_id = item.product_id
      }
      else {
        ios_sku_id = item.product_id
      }
    })

    this.setState({
      android_sku_id,
      android_lite_sku_id,
      ios_sku_id,
    })
  }

  componentWillUnmount() {
    this._resetState()
  }

  _resetState() {
    this.setState({
      title: "",
      orig_price: null,
      discount_percentage: null,
      discounted_price: null,
      jems_count: null,
    })
  }

  _onImageRemove = type => {
    if (type === "thumbnail") {
      this.setState({
        package_thumbnail_url_list: [],
        thumbnailImage: null,
      })
    }
  }

  _getAndroidRegistration = sku_id => {
    return {
      sku: sku_id,
      status: "active",
      purchaseType: "managedUser",
      listings: {
        "en-US": {
          title: this.state.title,
          description: this.state.title,
        },
      },
      defaultLanguage: "en-US",
      defaultPrice: {
        priceMicros: this.state.discounted_price
          ? this.state.discounted_price * 1000000
          : this.state.orig_price * 1000000,
        currency: this.state.default_currency,
      },
      prices: {
        IN: {
          priceMicros: this.state.discounted_price
            ? this.state.discounted_price * 1000000
            : this.state.orig_price * 1000000,
          currency: "INR",
        },
      },
      managedProductTaxesAndComplianceSettings: {
        eeaWithdrawalRightType: "WITHDRAWAL_RIGHT_DIGITAL_CONTENT",
      },
    }
  }

  _handleInAppRegistration = async type => {
    let data = this._getAndroidRegistration(this.state.android_sku_id)
    if (type=="android_lite"){
      data = this._getAndroidRegistration(this.state.android_lite_sku_id)
    }

    if (type === "ios") {
      data = {
        type: "inAppPurchases",
        attributes: {
          name: this.state.title,
          productId: this.state.ios_sku_id,
          inAppPurchaseType: "CONSUMABLE",
        },
        relationships: {
          app: {
            data: {
              id: "1530819820",
              type: "apps",
            },
          },
        },
      }
    }

    try {
      const result = await registerPackage({
        data,
        type,
        packageData: this.props.data,
      })
      if (result) {
        toastr.clear()
        toastr.success("Regisration successful !!")
      }
    } catch (error) {
      toastr.clear()
      toastr.error("Registration failed !!")
    }
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "thumbnail") {
        this.setState({
          package_thumbnail_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          thumbnailImage: fileObj.file,
        })
      }
    }
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Add" : "Edit"
          } Package`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={4} className="mt-2">
                <label>Title</label>
                <input
                  className="form-control"
                  value={this.state.title}
                  onChange={e => this.setState({ title: e.target.value })}
                />
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Tag Title</label>
                <input
                  className="form-control"
                  value={this.state.tag_title}
                  onChange={e => this.setState({ tag_title: e.target.value })}
                />
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Tag Color</label>
                <input
                  className="form-control form-control-color w-100"
                  type="color"
                  value={this.state.tag_colour}
                  onChange={e => this.setState({ tag_colour: e.target.value })}
                />
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Jems Count</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.jems_count}
                    onChange={e => {
                      if (e.target.value && e.target.value < 0) {
                        toastr.error("Jems count can not be zero...")
                        return
                      }
                      this.setState({ jems_count: e.target.value })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Package Url</label>
                <input
                  className="form-control"
                  value={this.state.package_url}
                  onChange={e => this.setState({ package_url: e.target.value })}
                />
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Currency</label>
                <input
                  className="form-control"
                  value={this.state.currency}
                  onChange={e => this.setState({ currency: e.target.value })}
                />
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Default Currency</label>
                <input
                  className="form-control"
                  value={this.state.default_currency}
                  onChange={e =>
                    this.setState({ default_currency: e.target.value })
                  }
                />
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Original Price</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.orig_price}
                    onChange={e => {
                      this.setState({
                        orig_price: e.target.value,
                      })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Discount Percentage (%)</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.discount_percentage}
                    onChange={e => {
                      this.setState({
                        discount_percentage: e.target.value,
                      })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Discounted Price</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.discounted_price}
                    onChange={e => {
                      this.setState({
                        discounted_price: e.target.value,
                      })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Conversion Factor</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.conversion_factor}
                    onChange={e => {
                      this.setState({
                        conversion_factor: e.target.value,
                      })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Hide Jems Package</label>
                <div>
                  <Switch
                    onChange={value => {
                      this.setState({ hide_jems_package: value })
                    }}
                    defaultValue={this.state.hide_jems_package}
                  />
                </div>
              </Col>
              <Col sm="12" md="12" className="mt-2">
                <Row style={{ marginTop: "10px" }}>
                  <Upload
                    showUploadList={true}
                    onRemove={() => this._onImageRemove("thumbnail")}
                    defaultFileList={this.state.package_thumbnail_url_list}
                    fileList={this.state.package_thumbnail_url_list}
                    customRequest={fileObj =>
                      this._uploadImage(fileObj, "thumbnail")
                    }
                  >
                    <Button color="primary" onClick={() => {}}>
                      <i className="fas fa-upload" />
                      &nbsp; Upload thumbnail url
                    </Button>
                  </Upload>
                </Row>
              </Col>
            </Row>
            {this.props.action === "update" && (
              <>
                <Divider>In App Purchase</Divider>
                <Row style={{ marginTop: "10px" }} className="d-flex">
                  <Col sm="12" md="4" className="mt-2">
                    <label>Android SKU Id</label>
                    <input
                      className="form-control"
                      field={"android_sku_id"}
                      onChange={e => {
                        this.setState({
                          android_sku_id: e.target.value,
                        })
                      }}
                      value={this.state.android_sku_id}
                    />
                  </Col>
                  <Col sm="12" md="4" className="mt-2 align-self-end">
                    <Button
                      color="primary"
                      onClick={() => this._handleInAppRegistration("android")}
                    >
                      &nbsp;Submit
                    </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }} className="d-flex">
                  <Col sm="12" md="4" className="mt-2">
                    <label>Android Lite SKU Id</label>
                    <input
                      className="form-control"
                      field={"android_lite_sku_id"}
                      onChange={e => {
                        this.setState({
                          android_lite_sku_id: e.target.value,
                        })
                      }}
                      value={this.state.android_lite_sku_id}
                    />
                  </Col>
                  <Col sm="12" md="4" className="mt-2 align-self-end">
                    <Button
                      color="primary"
                      onClick={() => this._handleInAppRegistration("android_lite")}
                    >
                      &nbsp;Submit
                    </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }} className="d-flex">
                  <Col sm="12" md="4" className="mt-2">
                    <label>iOS SKU Id</label>
                    <input
                      field={"ios_sku_id"}
                      className="form-control"
                      onChange={e => {
                        this.setState({
                          ios_sku_id: e.target.value,
                        })
                      }}
                      value={this.state.ios_sku_id}
                    />
                  </Col>
                  <Col sm="12" md="4" className="mt-2 align-self-end">
                    <Button
                      color="primary"
                      onClick={() => this._handleInAppRegistration("ios")}
                    >
                      &nbsp;Submit
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                <div
                  className="flex justify-content-end"
                  style={{ marginTop: "10px" }}
                >
                  <div className="d-flex">
                    <Button htmlType="submit" color="primary">
                      {this.props.action === "update" ? "Update" : "Create"}
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.onCancel()
                        this._resetState()
                      }}
                      color="danger"
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    let data = new FormData()
    const payload = {}
    const productInfo = []
    const { data: packageData = {} } = this.props || {}
    const { package_element_uuid } = packageData || {}
    const {
      title,
      jems_count,
      discount_percentage,
      discounted_price,
      orig_price,
      thumbnailImage,
      tag_colour,
      tag_title,
      package_url,
      default_currency,
      currency,
      ios_sku_id,
      android_sku_id,
      android_lite_sku_id,
      conversion_factor,
      hide_jems_package,
    } = this.state

    if (package_element_uuid) {
      payload.package_element_uuid = package_element_uuid
    }
    if (title) {
      payload.title = title
    }
    if (jems_count || jems_count == 0) {
      payload.jems_count = +jems_count
    }
    if (discount_percentage || discount_percentage == 0) {
      payload.discount_percentage = +discount_percentage
    }
    if (discounted_price || discounted_price == 0) {
      payload.discounted_price = +discounted_price
    }

    if (conversion_factor) {
      payload.conversion_factor = +conversion_factor
    }

    if (hide_jems_package) {
      payload.hide_jems_package = hide_jems_package
    } else {
      payload.hide_jems_package = false
    }

    if (orig_price || orig_price == 0) {
      payload.orig_price = +orig_price
    }
    if (tag_title) {
      payload.tag_title = tag_title
    }
    if (tag_colour) {
      payload.tag_colour = tag_colour
    }
    if (currency) {
      payload.currency = currency
    }
    if (default_currency) {
      payload.default_currency = default_currency
    }
    if (package_url) {
      payload.package_url = package_url
    }
    if (thumbnailImage) {
      data.append("package_thumbnail_url", thumbnailImage)
    }

    if (android_sku_id) {
      productInfo.push({
        platform: "android",
        product_id: android_sku_id,
      })
    }

    if(android_lite_sku_id){
      productInfo.push({
        platform: "android_lite",
        product_id: android_lite_sku_id
      })
    }

    if (ios_sku_id) {
      productInfo.push({
        platform: "ios",
        product_id: ios_sku_id,
      })
    }

    payload.productInfo = productInfo

    data.append("payload", JSON.stringify(payload))
    this.props.onSubmit(data)
    this._resetState()
  }

  render() {
    return this._form()
  }
}
