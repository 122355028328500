import { getAgencyCreators, getAgencyStats } from "appRedux/slices/agency"
import React, { useEffect, useState } from "react"
import QueryString from "query-string"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Card, Col, Row } from "reactstrap"
import Auxiliary from "util/Auxiliary"
import { formatNumber, millisecondsToHHMMSS } from "util/common"
import {
  Table,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Button,
} from "@mui/material"
import Chips from "components/CommonFilter/chips"
import { Pagination } from "antd"
import { MdEdit } from "react-icons/md"

export default function AgencyStats(props) {
  const dispatch = useDispatch()
  const urlQueryParams = new URLSearchParams(props.location.search)
  if (!urlQueryParams.get("currentPage")) {
    urlQueryParams.set("currentPage", 1)
    props.history.push(
      `${props.location.pathname}?${urlQueryParams.toString()}`
    )
  }
  const initialCurrentPage = parseInt(urlQueryParams.get("currentPage")) || 1
  const status = urlQueryParams.get("status") || ""
  const user_uuid = urlQueryParams.get("user_uuid") || ""

  const [showFilter, setShowFilter] = useState(false)

  const [filters, setFilters] = useState({
    pageSize:
      props.queryParams && props.queryParams["pageSize"]
        ? props.queryParams["pageSize"]
        : 10,
    currentPage: initialCurrentPage,
    status,
    user_uuid,
  })

  const { agencyData, loading, creatorsData, creatorsTotal } = useSelector(
    state => state.agencyStats
  )
  const {
    currentUser: { data },
  } = useSelector(state => state.currentUser)

  useEffect(() => {
    dispatch(getAgencyStats(data.partnerId))
    dispatch(
      getAgencyCreators({
        partnerId: data.partnerId,
        ...filters,
      })
    )
  }, [])

  const getCreatorData = id => {
    props.history.push(`/creator/stats?userUUID=${id}`)
  }

  const handlePageChange = value => {
    let newFilters = _.cloneDeep(filters)
    newFilters["currentPage"] = value

    const queryParams = new URLSearchParams(props.history.location.search)
    queryParams.set("currentPage", value)
    props.history.push(`${props.location.pathname}?${queryParams.toString()}`)

    setFilters(newFilters)

    dispatch(
      getAgencyCreators({
        partnerId: data.partnerId,
        ...newFilters,
      })
    )
  }

  const _toggleFilters = () => {
    setShowFilter(!showFilter)
  }

  const _closeFilters = async () => {
    const payload = {
      partnerId: data.partnerId,
      ...filters,
    }
    console.log(`payload: ${JSON.stringify(payload)}`)

    dispatch(getAgencyCreators(payload))
    props.history.push(
      `${location.pathname}?${QueryString.stringify(_.deepClean(filters))}`
    )
    updateURLParams(filters)
    setShowFilter(false)
  }

  const updateURLParams = newFilters => {
    const queryParams = new URLSearchParams()

    // Set pagination values
    queryParams.set("currentPage", String(newFilters.currentPage))
    queryParams.set("pageSize", String(newFilters.pageSize))

    // Add all the other active filters to the URL
    Object.keys(newFilters).forEach(key => {
      const value = newFilters[key]
      if (value) {
        queryParams.set(key, String(value))
      } else {
        queryParams.delete(key) // Remove from URL if filter is unset
      }
    })
    props.history.push(`${location.pathname}?${queryParams.toString()}`)
  }

  const _removeFilter = filterKey => {
    const newFilters = _.cloneDeep(filters)
    newFilters["currentPage"] = 1 // Reset page to 1
    _.unset(newFilters, filterKey)
    setFilters(newFilters)
    const queryParams = new URLSearchParams(props.history.location.search)
    queryParams.set("currentPage", "1")
    props.history.push(`${location.pathname}?${queryParams.toString()}`)
    updateURLParams(newFilters)
    dispatch(
      getAgencyCreators({
        partnerId: data.partnerId,
        ...newFilters,
      })
    )
  }

  const _handleFilterChange = (value, type) => {
    const queryParams = new URLSearchParams(props.history.location.search)
    queryParams.set("currentPage", "1")
    props.history.push(`${location.pathname}?${queryParams.toString()}`)
    const newFilters = _.cloneDeep(filters)
    newFilters[type] = value
    newFilters["currentPage"] = 1
    setFilters(newFilters)
  }

  return (
    <Auxiliary loading={loading}>
      <Row>
        <Chips
          showFilters={showFilter}
          filters={filters}
          search={_closeFilters}
          removeFilter={_removeFilter}
          handleFilterChange={_handleFilterChange}
          type="agency_stats"
          toggleFilters={_toggleFilters}
        />
      </Row>
      <Row>
        <Col lg="6">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Total Diamonds Earned</h3>
              <h4>{formatNumber(agencyData.total_diamonds_count)}</h4>
            </div>
          </Card>
        </Col>
        <Col lg="6">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Earnings in next billing cycle</h3>
              <h4>{formatNumber(agencyData.total_redeemable_diamond)}</h4>
            </div>
          </Card>
        </Col>
      </Row>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="large" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Creator Id</b>
                </TableCell>
                <TableCell>
                  <b>Status</b>
                </TableCell>
                <TableCell>
                  <b>User Name</b>
                </TableCell>
                <TableCell>
                  <b>Live Call Name</b>
                </TableCell>
                <TableCell>
                  <b>Rating</b>
                </TableCell>
                <TableCell>
                  <b>Diamonds Earned</b>
                </TableCell>
                <TableCell>
                  <b>Time Spent (HH:MM:SS)</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {creatorsData.map(row => {
                return (
                  <TableRow
                    style={{ cursor: "pointer" }}
                    onClick={() => getCreatorData(row.user_uuid.trim())}
                  >
                    <TableCell>{row.user_uuid}</TableCell>
                    <TableCell>
                      {row?.live_call_enabled ? "Active" : "In-Active"}
                    </TableCell>
                    <TableCell>{row.user_name}</TableCell>
                    <TableCell>{row.live_call_name}</TableCell>
                    <TableCell>{row.avg_rating}</TableCell>
                    <TableCell>
                      {formatNumber(row.total_diamonds_count)}
                    </TableCell>
                    <TableCell>
                      {millisecondsToHHMMSS(row.total_time_spent)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="d-flex justify-content-end pb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={handlePageChange}
            current={filters.currentPage}
            total={creatorsTotal < 10000 ? creatorsTotal : 10000}
            pageSize={filters.pageSize}
            showSizeChanger={false}
          />
        </div>
      </Paper>
    </Auxiliary>
  )
}
