import React, { useEffect, useState } from "react"
import { getAgencyStats } from "appRedux/slices/agency"
import QueryString from "query-string"
import { Card, Col, Row } from "reactstrap"
import Auxiliary from "util/Auxiliary"
import { getSeriesDataPaginate } from "clientServices/audioStories"
import { useSelector, useDispatch } from "react-redux"
import { formatNumber } from "util/common"

import {
  Table,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material"
import Chips from "components/CommonFilter/chips"
import { Pagination } from "antd"

const AudioStoryStats = props => {
  const [audioSeriesData, setAudioSeriesData] = useState([])
  const [loading, setLoading] = useState(true)
  const urlQueryParams = new URLSearchParams(props.location.search)

  const dispatch = useDispatch()

  const { currentUser } = useSelector(state => state.currentUser)

  const { agencyData, creatorsData, creatorsTotal } = useSelector(
    state => state.agencyStats
  )

  const [showFilter, setShowFilter] = useState(false)

  const [filters, setFilters] = useState({
    pageSize: 10,
    currentPage: 1,
  })

  useEffect(() => {
    dispatch(getAgencyStats(currentUser.data.partnerId))
    const newFilters = { ...filters }
    urlQueryParams.forEach((value, key) => {
      newFilters[key] = value
    })
    setFilters(newFilters)
    fetchAndSetAudioSeriesData(newFilters)
  }, [])

  const fetchAndSetAudioSeriesData = async newFilters => {
    try {
      setLoading(true)
      const nf = { ...newFilters, partnerId: currentUser.data.partnerId }
      const seriesData = await getSeriesDataPaginate({ filters: nf })
      setAudioSeriesData(seriesData)
    } finally {
      setLoading(false)
    }
  }

  const handlePageChange = async value => {
    const newFilters = { ...filters, currentPage: value }
    const queryParams = new URLSearchParams(props.history.location.search)
    queryParams.set("currentPage", value.toString())
    props.history.push(`${props.location.pathname}?${queryParams.toString()}`)
    setFilters(newFilters)
    await fetchAndSetAudioSeriesData(newFilters)
  }

  const _toggleFilters = () => {
    setShowFilter(!showFilter)
  }

  const _closeFilters = async () => {
    await fetchAndSetAudioSeriesData(filters)
    props.history.push(
      `${props.location.pathname}?${QueryString.stringify(filters)}`
    )
    updateURLParams(filters)
    setShowFilter(false)
  }

  const updateURLParams = newFilters => {
    const queryParams = new URLSearchParams()
    queryParams.set("currentPage", String(newFilters.currentPage))
    queryParams.set("pageSize", String(newFilters.pageSize))
    Object.keys(newFilters).forEach(key => {
      const value = newFilters[key]
      if (value) {
        queryParams.set(key, String(value))
      } else {
        queryParams.delete(key)
      }
    })
    props.history.push(`${props.location.pathname}?${queryParams.toString()}`)
  }

  const _removeFilter = async filterKey => {
    const newFilters = { ...filters, currentPage: 1 }
    delete newFilters[filterKey]
    setFilters(newFilters)
    const queryParams = new URLSearchParams(props.history.location.search)
    queryParams.set("currentPage", "1")
    props.history.push(`${props.location.pathname}?${queryParams.toString()}`)
    await fetchAndSetAudioSeriesData(newFilters)
    updateURLParams(newFilters)
  }

  const _handleFilterChange = (value, type) => {
    const newFilters = { ...filters, seriesId: value, currentPage: 1 }
    setFilters(newFilters)
  }

  return (
    <Auxiliary loading={loading}>
      <Row>
        <Chips
          showFilters={showFilter}
          filters={filters}
          search={_closeFilters}
          removeFilter={_removeFilter}
          handleFilterChange={_handleFilterChange}
          type="audio_series"
          toggleFilters={_toggleFilters}
        />
      </Row>
      <Row>
        <Col lg="6">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Total Diamonds Earned</h3>
              <h4>{formatNumber(agencyData.total_diamonds_count)}</h4>
            </div>
          </Card>
        </Col>
        <Col lg="6">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Earnings in next billing cycle</h3>
              <h4>{formatNumber(agencyData.total_redeemable_diamond)}</h4>
            </div>
          </Card>
        </Col>
      </Row>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Series Id</b>
                </TableCell>
                <TableCell>
                  <b>Title</b>
                </TableCell>
                <TableCell>
                  <b>Status</b>
                </TableCell>
                <TableCell>
                  <b>Stories Count</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {audioSeriesData?.data?.map(row => (
                <TableRow
                  key={row.series_id}
                  // style={{ cursor: "pointer" }}
                  //   onClick={() => getCreatorData(row.user_uuid.trim())}
                >
                  <TableCell>{row.series_id}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.story_count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="d-flex justify-content-end pb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={handlePageChange}
            current={Number(filters.currentPage)}
            total={
              audioSeriesData?.total?.value
                ? audioSeriesData?.total?.value
                : 100
            }
            pageSize={Number(filters.pageSize)}
            showSizeChanger={false}
          />
        </div>
      </Paper>
    </Auxiliary>
  )
}

export default AudioStoryStats
