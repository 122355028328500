import { Tooltip } from "antd"
import { map, size } from "lodash"
import React from "react"
import ReactCardFlip from "react-card-flip"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Link } from "react-router-dom"
import ReactTimeAgo from "react-time-ago"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import CustomScrollbars from "util/CustomScrollbars"
import {
  LANGUAGES,
  LANGUAGE_MAPPING,
  NO_PERMISSION_MESSAGE,
} from "../../constants/uiConstants"
import "./styles.css"
import { Chip } from "@mui/material"
class StoriesCard extends React.Component {
  state = { isPlaying: false, isFlipped: false, isPlayedFirst: true }
  audioRef = React.createRef()

  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  _playPreview = () => {
    if (this.state.isPlayedFirst) {
      this.audioRef.currentTime = this.props.audio.default_start_selection_ms
        ? this.props.audio.default_start_selection_ms / 1000
        : 0
      this.setState({ isPlayedFirst: false })
    }
    var that = this

    var pauseAudioAt = function (aud, seconds, listenerToRemove) {
      // check whether we have passed 5 minutes,
      // current time is given in seconds
      if (aud.currentTime >= seconds) {
        // pause the playback
        aud.pause()
        if (typeof listenerToRemove === "function") {
          aud.removeEventListener("timeupdate", listenerToRemove)
          that.setState({ isPlaying: false, isPlayedFirst: true })

          // showBtn();
        }
      }
    }
    this.audioRef.addEventListener("timeupdate", function listener1() {
      pauseAudioAt(
        this,
        that.props.audio.default_end_selection_ms / 1000,
        listener1
      )
    })

    if (!this.state.isPlaying) {
      this.setState({ isPlaying: true })
      this.audioRef.play()
    } else {
      this.setState({ isPlaying: false })
      this.audioRef.pause()
      this.audioRef.removeEventListener("timeupdate", function () {})
    }
  }

  render() {
    const { audio, audioDetails = [], customClassname = "" } = this.props

    return (
      <Card
        className={`${customClassname} text-center shadow-lg`}
        style={{
          minHeight: "280px",
          borderRadius: "10px",
          width: "80%",
        }}
      >
        <div className="bg-primary bg-soft" onClick={() => {}}>
          <div>
            {audio && audio.cover_url ? (
              <div
                role="button"
                id={"play" + audio.story_id}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  this._playPreview()
                }}
                style={{ height: "15rem" }}
              >
                <CardImg
                  top
                  className="img-fluid"
                  src={audio.cover_url}
                  alt="Skote"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  style={{
                    height: "15rem",
                    cursor: "pointer",
                    opacity: "0.8",
                  }}
                />

                <i
                  key={audio.story_id}
                  className={
                    this.state.isPlaying ? "bx bx-pause" : "bx bx-play"
                  }
                  style={{
                    position: "absolute",
                    top: "35%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "120px",
                    color: "white",
                  }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={"play" + audio.story_id}
                >
                  Play Audio
                </UncontrolledTooltip>
                <audio
                  ref={input => {
                    this.audioRef = input
                  }}
                  src={audio.stream_url}
                  style={{ display: "none" }}
                />
              </div>
            ) : (
              <div
                className="py-5"
                style={{
                  height: "15rem",
                  cursor: "pointer",
                  backgroundColor: "lightgray",
                }}
              >
                <i className="fas fa-volume-mute fa-7x py-4 text-secondary" />
              </div>
            )}
          </div>
        </div>
        <CardBody style={{ padding: "1rem 0.25rem" }}>
          <Tooltip title={audio.title}>
            <div className="font-size-14 text-truncate font-weight-semibold">
              {audio.title}
            </div>
          </Tooltip>
          <Tooltip title={"Story Number"}>
            <Chip
              color="primary"
              variant="outlined"
              size="small"
              label={
                audio.story_id
                  ? _.capitalize(audio.story_number)
                  : _.capitalize(_.size(audio.elements))
              }
            />
          </Tooltip>
          {audio.status ? (
            <Tooltip title={"Status"}>
              <Chip
                color="primary"
                variant="outlined"
                size="small"
                className="m-2"
                label={_.capitalize(audio.status)}
              />
            </Tooltip>
          ) : null}
          {audio.source ? (
            <Tooltip title={"Source"}>
              <Chip
                color="primary"
                variant="outlined"
                size="small"
                label={_.capitalize(audio.source)}
              />
            </Tooltip>
          ) : null}
          {audio.is_independent_story ? (
            <Tooltip>
              <Chip
                color="primary"
                variant="outlined"
                size="small"
                className="mx-1"
                label={"Independent Story"}
              />
            </Tooltip>
          ) : null}
          {audio.type && (
            <Tooltip title={"Type"}>
              <div className="font-size-14 text-truncate font-weight-semibold">
                {audio.type}
              </div>
            </Tooltip>
          )}
        </CardBody>
        <div
          className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          {audio.story_id && (
            <div className="flex-fill">
              <CopyToClipboard
                text={audio.story_id}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Audio ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
          )}
          {audio.series_id && (
            <div className="flex-fill">
              <CopyToClipboard
                text={audio.series_id}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Series ID">
                  <i className="bx bx-copy-alt" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
          )}
          {audio.collection_id && (
            <div className="flex-fill">
              <CopyToClipboard
                text={audio.collection_id}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Collection ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
          )}
          <div className="flex-fill">
            <Tooltip title="Edit">
              <i
                className="bx bx-edit-alt"
                role="button"
                onClick={() => {
                  // if (
                  //   !this.props.currentUser?.permissions?.includes(
                  //     "EDIT_MANAGE_AUDIO"
                  //   )
                  // ) {
                  //   toastr.error(NO_PERMISSION_MESSAGE)
                  //   return
                  // }
                  this.props.onEditClick("update", audio)
                }}
              />
            </Tooltip>
          </div>
        </div>
      </Card>
    )
  }
}

export default StoriesCard
