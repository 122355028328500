import { call, takeLatest, put } from "redux-saga/effects"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"
import {
  getGifts,
  getGiftsSuccess,
  upsertGift,
  upsertGiftSuccess,
  upsertGiftCollection,
  upsertGiftCollectionSuccess,
  reorderGiftCollectionEntity,
  reorderGiftCollectionEntitySuccess,
  upsertGiftCollectionEntity,
  upsertGiftCollectionEntitySuccess,
  deleteGifts,
  deleteGiftsSuccess,
  upgradeGiftListVersion,
  upgradeGiftListVersionSuccess,
  upgradeGiftListVersion2,
  upgradeGiftListVersion2Success,
} from "../../slices/gift"
import {
  requestGifts,
  requestUpsertGift,
  requestUpsertGiftCollection,
  requestReorderGiftCollectionEntity,
  requestUpsertGiftCollectionEntity,
  requestDeleteGifts,
  requestUpgradeGiftListVersion,
  requestUpgradeGiftListVersion2,
} from "../requests/gift"

function* handleEntities(params) {
  try {
    const data = yield call(requestGifts, params)
    yield put(getGiftsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertGift(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertGift, params)
    yield put(upsertGiftSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertGiftCollection(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertGiftCollection, params)
    yield put(upsertGiftCollectionSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleReorderGiftCollectionEntity(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestReorderGiftCollectionEntity, params)
    yield put(reorderGiftCollectionEntitySuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertGiftCollectionEntity(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertGiftCollectionEntity, params)
    yield put(upsertGiftCollectionEntitySuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteGifts(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestDeleteGifts, params)
    yield put(deleteGiftsSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpgradeGiftListVersion(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpgradeGiftListVersion, params)
    yield put(upgradeGiftListVersionSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}
function* handleUpgradeGiftListVersion2(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpgradeGiftListVersion2, params)
    yield put(upgradeGiftListVersion2Success())
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getGifts.type, handleEntities)
  yield takeLatest(upsertGift.type, handleUpsertGift)
  yield takeLatest(upsertGiftCollection.type, handleUpsertGiftCollection)
  yield takeLatest(
    reorderGiftCollectionEntity.type,
    handleReorderGiftCollectionEntity
  )
  yield takeLatest(
    upsertGiftCollectionEntity.type,
    handleUpsertGiftCollectionEntity
  )
  yield takeLatest(deleteGifts.type, handleDeleteGifts)
  yield takeLatest(upgradeGiftListVersion.type, handleUpgradeGiftListVersion)
  yield takeLatest(upgradeGiftListVersion2.type, handleUpgradeGiftListVersion2)
}
