import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import Common from "appRedux/slices/common"
import CurrentUser from "appRedux/slices/currentUser"
import CMSUser from "appRedux/slices/cmsUser"
import ContentReducer from "appRedux/slices/content"
import CommentReducer from "appRedux/slices/comment"
import AppUserReducer from "appRedux/slices/appUser"
import ChallengeReducer from "appRedux/slices/challenge"
import AudioReducer from "appRedux/slices/audio"
import ZeroSearchReducer from "appRedux/slices/zeroSearch"
import GiftReducer from "appRedux/slices/gift"
import PackageReducer from "appRedux/slices/package"
import LocationReducer from "appRedux/slices/location"
import HashtagReducer from "appRedux/slices/hashtag"
import ViewActivityReducer from "appRedux/slices/activity"
import CameraAssetReducer from "appRedux/slices/cameraAsset"
import VideoAssetsReducer from "appRedux/slices/videoAssets"
import CMSVideoAssetsReducer from "appRedux/slices/cmsVideoAssets"
import CMSVideoTemplatesReducer from "appRedux/slices/cmsVideoTemplates"
import StickersReducer from "appRedux/slices/stickers"
import LayoutReducer from "appRedux/slices/layout"
import CommunityReducer from "appRedux/slices/community"
import TrendingReducer from "appRedux/slices/trending"
import SourceReducer from "appRedux/slices/source"
import ContestReducer from "appRedux/slices/contest"
import CampaignReducer from "appRedux/slices/campaign"
import SponsoredTagsReducer from "appRedux/slices/sponsoredTags"
import uiConstantsReducer from "appRedux/slices/uiConstants"
import JoshliveReducer from "appRedux/slices/joshlive"
import ReviewReducer from "appRedux/slices/review"
import CreatorIssuesReducer from "appRedux/slices/creatorIssues"
import issuesComments from "appRedux/slices/issuesComments"
import EventReducer from "../slices/events"
import IconsReducer from "appRedux/slices/icons"
import TaxonomyReducer from "appRedux/slices/taxonomy"
import ImageReducer from "appRedux/slices/image"
import ZoneReducer from "appRedux/slices/zone"
import SocialReducer from "appRedux/slices/social"
import TemplateSection from "appRedux/slices/templateSection"
import MemeSlice from "appRedux/slices/meme"
import AdsReducer from "appRedux/slices/ads"
import LimitReducer from "appRedux/slices/limit"
import CtaReducer from "appRedux/slices/cta"
import ListingsReducer from "appRedux/slices/listings"
import KYCReducer from "appRedux/slices/kyc"
import TinodeReducer from "appRedux/slices/tinode"
import DynamicFormReducer from "appRedux/slices/dynamicForm"
import BadgeReducer from "appRedux/slices/badges"
import AgencyReducer from "appRedux/slices/agency"

const reducers = combineReducers({
  routing: routerReducer,
  common: Common,
  currentUser: CurrentUser,
  cmsUser: CMSUser,
  content: ContentReducer,
  comment: CommentReducer,
  appUser: AppUserReducer,
  challenge: ChallengeReducer,
  audio: AudioReducer,
  zeroSearch: ZeroSearchReducer,
  gift: GiftReducer,
  locationReducer: LocationReducer,
  uiConstants: uiConstantsReducer,
  hashtag: HashtagReducer,
  activityLog: ViewActivityReducer,
  cameraAsset: CameraAssetReducer,
  videoAssets: VideoAssetsReducer,
  cmsVideoAssets: CMSVideoAssetsReducer,
  cmsVideoTemplates: CMSVideoTemplatesReducer,
  stickers: StickersReducer,
  Layout: LayoutReducer,
  community: CommunityReducer,
  trending: TrendingReducer,
  source: SourceReducer,
  contest: ContestReducer,
  campaign: CampaignReducer,
  sponsoredTags: SponsoredTagsReducer,
  joshlive: JoshliveReducer,
  review: ReviewReducer,
  creatorIssues: CreatorIssuesReducer,
  issueComments: issuesComments,
  events: EventReducer,
  icons: IconsReducer,
  package: PackageReducer,
  taxonomy: TaxonomyReducer,
  image: ImageReducer,
  zone: ZoneReducer,
  social: SocialReducer,
  templateSection: TemplateSection,
  meme: MemeSlice,
  ads: AdsReducer,
  limit: LimitReducer,
  cta: CtaReducer,
  listings: ListingsReducer,
  kyc: KYCReducer,
  tinode: TinodeReducer,
  dynamicForm: DynamicFormReducer,
  badge: BadgeReducer,
  agencyStats: AgencyReducer,
})

export default reducers
