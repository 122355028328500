import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "reactstrap"
import Auxiliary from "util/Auxiliary"
import { formatNumber, msToHMS } from "util/common"
import {
  Table,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material"
import { Pagination, Tag } from "antd"
import {
  diamondsByUsers,
  getLiveCallingLogData,
  getLiveCallingUserData,
} from "clientServices/liveCallingService"
import Chips from "components/CommonFilter/chips"
import toastr from "toastr"

export default function CreatorStats(props) {
  const urlQueryParams = new URLSearchParams(props.location.search)
  const user_uuid = urlQueryParams.get("userUUID") || ""
  const initialFilters = {
    pageSize: urlQueryParams.get("pageSize") || 10,
    currentPage: urlQueryParams.get("currentPage") || 1,
    createdDateFrom: urlQueryParams.get("createdDateFrom") || "",
    createdDateTo: urlQueryParams.get("createdDateTo") || "",
    userUUID: user_uuid,
  }

  const [filters, setFilters] = useState(initialFilters)
  const [userUUid, setUserUUID] = useState(user_uuid)
  const [userInfo, setUserInfo] = useState(null)
  const [diamondInfo, setDiamondInfo] = useState(null)
  const [userInfoLog, setUserInfoLog] = useState(null)
  const [loading, setLoading] = useState(false)

  const [showFilter, setShowFilter] = useState(false)

  const _getModerationImage = props => {
    return userInfo?.profile_pic
  }

  useEffect(() => {
    if (userUUid) {
      fetchAndSetUserData(userUUid)
    }
  }, [loading])

  const fetchAndSetUserData = async userUUID => {
    const res = await getLiveCallingUserData({ filters: { id: userUUID } })
    setUserInfo(res.data[0])
    // Here filters will have current page value which is by default is 1 which is declared in above filter state and changed when userUUid is changed
    const log = await getLiveCallingLogData({
      filters: { id: userUUID, ...filters },
    })
    setUserInfoLog({
      ...log.data,
      ...{ total: log.total },
      ...{ logData: log.itemData?.data },
      ...{ acceptanceStats: log.acceptanceStats },
    })
    const diamondsPerUser = await diamondsByUsers([userUUID])
    setDiamondInfo(diamondsPerUser[userUUID])
    setLoading(false)
  }

  const handleModal = status => {
    setShowAddVideosPopup(status)
  }

  const _onPageChange = async value => {
    setLoading(true)
    const newFilters = _.cloneDeep(filters)
    newFilters["currentPage"] = value
    setFilters(newFilters)
    let log = await getLiveCallingLogData({
      filters: { id: userUUid, ...newFilters },
    })
    setUserInfoLog({
      ...log.data,
      ...{ total: log.total },
      ...{ logData: log.itemData?.data },
      ...{ acceptanceStats: log.acceptanceStats },
    })
    updateURLParams(newFilters)
  }

  const _toggleFilters = () => {
    setShowFilter(!showFilter)
  }

  const _closeFilters = async () => {
    setLoading(true)
    const newFilters = _.cloneDeep(filters)
    newFilters["currentPage"] = 1
    setFilters(newFilters)
    setShowFilter(false)
    updateURLParams(newFilters)
  }

  const updateURLParams = newFilters => {
    const queryParams = new URLSearchParams()

    // Set pagination values
    queryParams.set("currentPage", String(newFilters.currentPage))
    queryParams.set("pageSize", String(newFilters.pageSize))

    // Add all the other active filters to the URL
    Object.keys(newFilters).forEach(key => {
      const value = newFilters[key]
      if (value) {
        queryParams.set(key, String(value))
      } else {
        queryParams.delete(key) // Remove from URL if filter is unset
      }
    })
    props.history.push(`${location.pathname}?${queryParams.toString()}`)
  }
  const _removeFilter = async filterKey => {
    let newFilters = _.cloneDeep(filters)
    if (filterKey === "userUUID") {
      newFilters = {
        pageSize: 10,
        currentPage: 1,
        createdDateFrom: "",
        createdDateTo: "",
        userUUID: "",
      }
      setUserUUID("")
      setUserInfo(null)
      setDiamondInfo(null)
      setUserInfoLog(null)
      setFilters(newFilters)
      // setLoading(true)
    } else {
      newFilters = _.cloneDeep(filters)
      _.unset(newFilters, filterKey)
      newFilters["currentPage"] = 1
      setFilters(newFilters)
      setLoading(true)
    }
    updateURLParams(newFilters)
  }

  const _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(filters)
    newFilters[type] = value
    newFilters["currentPage"] = 1
    if (type === "userUUID") {
      setUserUUID(value)
    }
    setFilters(newFilters)
  }
  return (
    <Auxiliary loading={loading}>
      <Row>
        <Chips
          showFilters={showFilter}
          filters={filters}
          search={_closeFilters}
          removeFilter={_removeFilter}
          handleFilterChange={_handleFilterChange}
          type="creator_stats"
          toggleFilters={_toggleFilters}
        />
      </Row>
      <Row>
        <Col lg="4">
          <Card className="p-3">
            <div
              className="d-flex align-items-center flex-column"
              title={userInfoLog?.acceptanceStats?.call_acceptance_label}
            >
              <h3>Call Acceptance Rate</h3>
              <h4>
                {userInfoLog?.acceptanceStats?.call_acceptance_rate
                  ? (
                      userInfoLog?.acceptanceStats?.call_acceptance_rate * 100
                    ).toFixed(2) + " %"
                  : "0%"}
              </h4>
            </div>
          </Card>
        </Col>
        <Col lg="4">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Total Diamonds Earned</h3>
              <h4>{formatNumber(diamondInfo?.total_diamonds_count || 0)}</h4>
            </div>
          </Card>
        </Col>
        <Col lg="4">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Today Earning Diamonds</h3>
              <h4>{formatNumber(diamondInfo?.today_earning || 0)}</h4>
            </div>
          </Card>
        </Col>
      </Row>

      {userInfoLog?.total > 0 &&
        userInfoLog?.logData &&
        userInfoLog?.logData.length > 0 && (
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <Tag color="geekblue">
              Showing <b>{userInfoLog?.logData?.length}</b> of{" "}
              <b>{formatNumber(userInfoLog.total)}</b> Calls
            </Tag>
          </div>
        )}

      {userInfoLog?.logData && userInfoLog?.logData.length > 0 && (
        <Paper style={{ marginBottom: "20px" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Time</b>
                  </TableCell>
                  <TableCell>
                    <b>Call Format</b>
                  </TableCell>
                  <TableCell>
                    <b>Call Type</b>
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    <b>Caller Id</b>
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    <b>Call Id</b>
                  </TableCell>
                  <TableCell>
                    <b>Duration of Call</b>
                  </TableCell>
                  <TableCell>
                    <b>Free Calls</b>
                  </TableCell>
                  <TableCell>
                    <b>Call End Reason</b>
                  </TableCell>
                  <TableCell>
                    <b>Rating</b>
                  </TableCell>
                  <TableCell>
                    <b>Refunded</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userInfoLog?.logData?.map((items, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {items?.created_date
                        ?.split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")}
                    </TableCell>
                    <TableCell>
                      {items?.created_date?.split("T")[1].split("+")[0]}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {items.comm_type}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {items.call_type}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {items.caller_id}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {items.id}
                    </TableCell>
                    <TableCell>
                      {items.duration ? msToHMS(items.duration) : "0:0:0"}
                    </TableCell>
                    <TableCell>{items.fc_unit}</TableCell>
                    <TableCell>{items.end_reason || "-"}</TableCell>
                    <TableCell>{items.rating || "-"}</TableCell>
                    <TableCell>{items.refund ? "Yes" : "No" || "-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="d-flex justify-content-end">
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              onChange={_onPageChange}
              defaultCurrent={Number(filters.currentPage)}
              total={userInfoLog?.total ? userInfoLog?.total : 10}
              pageSize={Number(filters.pageSize)}
              showSizeChanger={false}
            />
          </div>
        </Paper>
      )}
    </Auxiliary>
  )
}
