import React from "react"
import { render } from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import store from "./appRedux/store/configureStore"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

const queryClient = new QueryClient()

const container = document.getElementById("root")

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
)

render(app, container)
