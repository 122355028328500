import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button } from "@mui/material"
import { withRouter } from "react-router-dom"
import DynamicForm from "./DynamicForm"
import { getDynamicForms, upsertDynamicForm } from "appRedux/slices/dynamicForm"
import { dslUpdate } from "clientServices/dslService"
import { MdArrowBack, MdArrowForward } from "react-icons/md"

class DSLForm extends ErrorBoundComponent {
  state = {
    data: [],
    filterdData: [],
    currentForm: 0,
    subInterestStatusList: {},
  }
  constructor(props) {
    super(props)
    this.handleFormValuesChange = this.handleFormValuesChange.bind(this)
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(getDynamicForms())
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }
  async handleFormValuesChange(field, value, type, action) {
    // Update the JSON structure with new form values
    // Here you can merge newFormValues into your existing JSON structure

    let newLayout = this.props.dynamicFormData.data
      .filter(item => item.name == this.props.queryParams.name)[0]
      .forms[this.state.currentForm].layout.map((item, index) => {
        if (
          item["field_name"] == field &&
          type == "object" &&
          action == "add"
        ) {
          return { ...item, value: { ...item.value, ...value }, modified: true }
        } else if (
          item["field_name"] == field &&
          type == "object" &&
          action == "delete"
        ) {
          let itemnew = { ...item, value: { ...item.value }, modified: true }
          delete itemnew.value[value]
          return itemnew
        } else if (item["field_name"] == field) {
          return { ...item, value: value, modified: true }
        } else {
          return item
        }
      })

    this.props.dispatch(
      upsertDynamicForm({
        layout: newLayout,
        currentForm: this.state.currentForm,
        formName: this.props.queryParams.name,
        alldata: this.props.dynamicFormData,
        // data: [{ ...this.props.dynamicFormData.data.filter(item=> item.name == this.props.queryParams.name)[0], layout: newLayout }],
      })
    )
  }
  _findFormErrors = () => {
    const newErrors = {}
    let form_layout = _.cloneDeep(
      this.props.dynamicFormData.data.filter(
        item => item.name == this.props.queryParams.name
      )[0].forms[this.state.currentForm].layout
    )
    // console.log(updated_values)
    console.log(this.state.currentForm)
    for (let i = 0; i < form_layout.length; i++) {
      console.log(form_layout[i].validations)
      if (form_layout[i].validations) {
        //all error handling code here
        if (form_layout[i].validations.required) {
          // if(form_layout[i].type == "string"){
          if (
            (form_layout[i].type == "string" &&
              (form_layout[i].value == undefined ||
                form_layout[i].value.trim() == "")) ||
            (form_layout[i].type == "array" &&
              (form_layout[i].value == undefined ||
                form_layout[i].value?.length === 0)) ||
            (form_layout[i].type == "number" &&
              (form_layout[i].value == undefined ||
                form_layout[i].value === ""))
          ) {
            form_layout[i].error_message = form_layout[i].label + " is required"
            newErrors[form_layout[i].field_name] = true
          } else {
            form_layout[i].error_message = undefined
          }
          // }
        }
        if (form_layout[i].validations.max) {
          if (
            form_layout[i].type == "array" &&
            (form_layout[i].value == undefined ||
              form_layout[i].value?.length > form_layout[i].validations.max)
          ) {
            form_layout[i].error_message =
              form_layout[i].label +
              " values are more than " +
              form_layout[i].validations.max
            newErrors[form_layout[i].field_name] = true
          }
        }
        // if(form_layout[i].validations.format){
        //   if (

        //     (form_layout[i].type == "array" &&
        //       (form_layout[i].value == undefined ||
        //         form_layout[i].value?.length > form_layout[i].validations.max))

        //   ) {
        //   }
        // }
      }
    }
    this.props.dispatch(
      upsertDynamicForm({
        layout: form_layout,
        currentForm: this.state.currentForm,
        formName: this.props.queryParams.name,
        alldata: this.props.dynamicFormData,
        // data: [{ ...this.props.dynamicFormData.data.filter(item=> item.name == this.props.queryParams.name)[0], layout: newLayout }],
      })
    )
    return newErrors
  }

  OnSubmit = async () => {
    // console.log(this.props.dynamicFormData)

    let updated_values = _.cloneDeep(
      this.props.dynamicFormData.data.filter(
        item => item.name == this.props.queryParams.name
      )[0]
    )

    for (let items of updated_values.forms) {
      for (let formItem of items.layout) {
        if (formItem.modified) {
          // _.get(updated_values.values,formItem.field_name)
          let form_field = formItem.field_name
          if (items.formName == "experiment_properties") {
            form_field = "experiment.properties." + form_field
          } else if (items.formName == "blending_config") {
            form_field = "blending_config." + form_field
          }
          _.set(updated_values.values, form_field, formItem.value)
        }
      }
    }

    let wholeDslJson = {}

    for (let dslData of this.props.dynamicFormData.data) {
      if (this.props.queryParams.name == dslData["name"]) {
        wholeDslJson[dslData["name"]] = updated_values.values
      } else {
        wholeDslJson[dslData["name"]] = dslData["values"]
      }
    }
    console.log(updated_values.values)
    await dslUpdate({
      data: { experiment_config: wholeDslJson },
    })
  }
  componentWillUnmount() {
    // this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }
  onFinish = () => {
    console.log("Submit success!")
  }

  render() {
    return (
      <Auxiliary
        loading={this.state.loading}
        error={_.get(this.props, "common.error")}
      >
        {
          <>
            <Button
              onClick={() => {
                this.props.history.push(`/dsl/list`)
              }}
              style={{
                marginBottom: "20px",
              }}
              variant="contained"
              className="mx-1"
              size="small"
              startIcon={<MdArrowBack />}
            >
              Back
            </Button>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              {this.state.currentForm < 2 && (
                <>
                  <Button
                    onClick={() => {
                      const errors = this._findFormErrors()
                      if (Object.keys(errors).length > 0) {
                        this.setState({
                          errors,
                        })
                        return
                      }
                      this.setState({
                        currentForm: this.state.currentForm + 1,
                      })
                    }}
                    variant="contained"
                    className="my-3"
                    size="small"
                    endIcon={<MdArrowForward />}
                    color="secondary"
                  >
                    Next
                  </Button>
                </>
              )}
              {this.state.currentForm >= 2 && (
                <Button
                  onClick={() => {
                    const errors = this._findFormErrors()
                    if (Object.keys(errors).length > 0) {
                      this.setState({
                        errors,
                      })
                      return
                    }
                    this.OnSubmit()
                  }}
                  color="success"
                  variant="contained"
                  className="my-3"
                  size="small"
                >
                  Submit
                </Button>
              )}
              {this.state.currentForm > 0 && (
                <Button
                  onClick={() => {
                    this.setState({
                      currentForm: this.state.currentForm - 1,
                    })
                  }}
                  variant="contained"
                  className="my-3 mx-1"
                  size="small"
                  startIcon={<MdArrowBack />}
                  color="secondary"
                >
                  Back
                </Button>
              )}
            </div>
            <DynamicForm
              formData={
                this.props.dynamicFormData?.data &&
                this.props.dynamicFormData?.data.length > 0 &&
                this.props.dynamicFormData?.data.filter(
                  item => item.name == this.props.queryParams.name
                )[0].forms[this.state.currentForm]
              }
              onFormValuesChange={this.handleFormValuesChange}
            />
          </>
        }
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    languageList: _.get(store, "uiConstants.languageList"),
    pageEntities: _.get(store, "zeroSearch.pageEntities"),
    locationList: _.get(store, "locationReducer.locationList"),
    dynamicFormData: _.get(store, "dynamicForm.dynamicFormData"),
  }
}

export default withRouter(connect(mapStateToProps)(DSLForm))
