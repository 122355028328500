import React, { Suspense, useEffect } from "react"
import { Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import { withRouter, Redirect } from "react-router-dom"
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from "appRedux/actions/layout.action"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"

//redux
import { useSelector, useDispatch } from "react-redux"
import Breadcrumb from "components/Common/Breadcrumb"
import { publicRoutes } from "../../routes/AppRoutes"
import Spinner from "../Spinner/Spinner"

const DashboardLayout = props => {
  const dispatch = useDispatch()

  const {
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    leftSideBarTheme,
  } = useSelector(state => ({
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: "default",
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }))
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(changeLayout("vertical"))
  }, [dispatch])

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme))
    }
  }, [leftSideBarTheme, dispatch])

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage))
    }
  }, [leftSideBarThemeImage, dispatch])

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth))
    }
  }, [layoutWidth, dispatch])

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType))
    }
  }, [leftSideBarType, dispatch])

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme))
    }
  }, [topbarTheme, dispatch])
  const currentPath = props.currentRoute.path
  let isAllow = false
  if (currentPath.includes("/user/verify")) {
    isAllow = true
  }
  if (!isAllow) {
    publicRoutes.forEach(({ path }) => {
      if (currentPath.includes(path)) {
        isAllow = true
      }
    })
  }

  if (!isAllow) {
    if (props.currentUser.jotUserType != 4) {
      isAllow = true
    }
  }
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          {...props}
          toggleMenuCallback={toggleMenuCallback}
          title={props.children.props.title}
          sideBarToggel={[
            "/content/embedding",
            "/dashboards/transaction",
            "/rooms/moderation",
          ].includes(props.children.props.match.path)}
        />
        <Sidebar
          currentUser={props.currentUser}
          theme="dark"
          type="default"
          isMobile={isMobile}
        />
        {props?.currentUser?.otpVerified || isAllow ? (
          <Suspense fallback={<Spinner />}>
            <div className="main-content">
              <div className="page-content">
                <MetaTags>
                  <title>
                    {props.children.props.title} | Josh Operations Tool
                  </title>
                </MetaTags>
                {props.children.props.match.path.includes(
                  "/content/embedding"
                ) ||
                props.children.props.match.path.includes("instant-messaging") ||
                props.children.props.match.path.includes("feed/interests") ||
                props.children.props.match.path.includes("feed/strategy") ||
                props.children.props.match.path.includes("feed/coldstart") ||
                props.children.props.match.path.includes("dsl/list") ? (
                  ""
                ) : (
                  <Breadcrumb
                    title={props.parentRoute.title}
                    breadcrumbItemLink={props.currentRoute.path}
                    breadcrumbItemTitle={props.currentRoute.title}
                  />
                )}
                <Container fluid>{props.children}</Container>
              </div>
            </div>
          </Suspense>
        ) : (
          props.currentRoute.path !== "/user/verify" && (
            <Redirect to="/user/verify" />
          )
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(DashboardLayout)
