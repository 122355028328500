import { call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import { fetchError } from "appRedux/slices/common"
import {
  deleteDynamicForm,
  deleteDynamicFormSuccess,
  getDynamicForms,
  getDynamicFormsByName,
  getDynamicFormsByNameSuccess,
  getDynamicFormsSuccess,
  upsertDynamicForm,
  upsertDynamicFormSuccess,
} from "appRedux/slices/dynamicForm"
import {
  requestDeleteDynamicForm,
  requestGetDynamicForms,
  requestGetDynamicFormsByName,
  requestUpsertDynamicForm,
} from "../requests/dynamicForm"

function* handleGetDynamicForms(params) {
  try {
    const data = yield call(requestGetDynamicForms, params)
    yield put(getDynamicFormsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetDynamicFormsByName(params) {
  try {
    const data = yield call(requestGetDynamicFormsByName, params)
    console.log("handle")
    console.log(data)
    yield put(getDynamicFormsByNameSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}
function* handleUpsertDynamicForm(params) {
  try {
    // const data = yield call(requestUpsertDynamicForm, params)
    yield put(upsertDynamicFormSuccess(params.payload))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteDynamicForm(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestDeleteDynamicForm, params)
    yield put(deleteDynamicFormSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getDynamicForms.type, handleGetDynamicForms)
  yield takeLatest(getDynamicFormsByName.type, handleGetDynamicFormsByName)
  yield takeLatest(upsertDynamicForm.type, handleUpsertDynamicForm)
  yield takeLatest(deleteDynamicForm.type, handleDeleteDynamicForm)
}
