import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { resetState } from "appRedux/slices/zone"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { getRatingList } from "clientServices/ratingService"
import { Typography } from "antd"
import { MdEdit, MdModeEditOutline } from "react-icons/md"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Button, Chip } from "@mui/material"
import { TiTick } from "react-icons/ti"
import { Dropdown, Space } from "antd"
import { AiOutlineStop } from "react-icons/ai"
import { MoreOutlined } from "@ant-design/icons"
import { upsertRating } from "clientServices/ratingService"

class ThemeficationList extends ErrorBoundComponent {
  state = {
    showInterestForm: false,
    filters: [],
    listData: [],
    errors: {},
    loading: true,
  }

  async componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      window.addEventListener("keydown", this._keyDownHandler)
    }
    const listData = await getRatingList()
    this.setState({
      listData,
      loading: false,
    })
    this.props.setListData(listData)
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  refetchData = async () => {
    this.setState({
      loading: true,
    })
    const listData = await getRatingList()
    this.setState({
      listData,
      loading: false,
    })
    this.props.setListData(listData)
  }

  render() {
    return (
      <Auxiliary
        loading={this.state.loading}
        error={_.get(this.props, "common.error")}
      >
        {" "}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Issue Type</b>
                  </TableCell>
                  <TableCell>
                    <b>Issue Description</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Issue Rank</b>
                  </TableCell>
                  <TableCell>
                    <b>User Type</b>
                  </TableCell>
                  <TableCell>
                    <b>Actions</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.listData.map(row => (
                  <TableRow style={{ height: "45px" }} key={row.issue_type}>
                    <TableCell>{row.issue_type.split("_").join(" ")}</TableCell>
                    <TableCell>{row.issue_description}</TableCell>
                    <TableCell>
                      {row.status === "ACTIVE" ? (
                        <Chip color="success" size="small" label="ACTIVE" />
                      ) : (
                        <Chip label="DISABLED" size="small" />
                      )}
                    </TableCell>
                    <TableCell>{row.issue_rank}</TableCell>
                    <TableCell>{row.user_type}</TableCell>
                    <TableCell className="d-flex">
                      <div
                        className="p-1 px-2"
                        style={{
                          border: "1px solid #1976d2",
                          borderRadius: "4px",
                        }}
                      >
                        <Dropdown
                          menu={{
                            items: [
                              {
                                key: "1",
                                label: (
                                  <Button
                                    style={{ color: "black" }}
                                    color="primary"
                                    className="btn-block btn btn-primary"
                                    onClick={async () => {
                                      this.props.setCurrentRow(row)
                                    }}
                                    startIcon={<MdModeEditOutline />}
                                  >
                                    Edit
                                  </Button>
                                ),
                              },
                              {
                                key: "2",
                                label: (
                                  <Button
                                    style={{ color: "black" }}
                                    color="primary"
                                    className="btn-block btn btn-primary"
                                    onClick={async () => {
                                      row.status === "ACTIVE"
                                        ? (row.status = "DISABLED")
                                        : (row.status = "ACTIVE")
                                      let data = { ...row, action: "update" }
                                      try {
                                        await upsertRating(data)
                                      } catch (error) {
                                      } finally {
                                        this.refetchData()
                                      }
                                    }}
                                    startIcon={
                                      row.status === "ACTIVE" ? (
                                        <AiOutlineStop />
                                      ) : (
                                        <TiTick />
                                      )
                                    }
                                  >
                                    {row.status === "ACTIVE"
                                      ? "Disable"
                                      : "Enable"}
                                  </Button>
                                ),
                              },
                            ],
                            selectable: true,
                          }}
                        >
                          <Typography.Link>
                            <Space>
                              <MoreOutlined
                                style={{ color: "#1976d2", marginTop: "9px" }}
                              />
                            </Space>
                          </Typography.Link>
                        </Dropdown>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-end pb-3">
            {/* <Pagination
              style={{ marginTop: "1rem" }}
              onChange={this._handlePageChange}
              defaultCurrent={this.state.filters.currentPage}
              total={this.state.total}
              pageSize={this.state.filters.pageSize}
              showSizeChanger={false}
            /> */}
          </div>
        </Paper>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    allZoneList: _.get(store, "zone.allZoneList"),
  }
}

export default connect(mapStateToProps)(ThemeficationList)
