import React from "react"
import { connect } from "react-redux"
import { Pagination, Input, Tag } from "antd"
import { Row, Col, CardBody, Card, CardTitle } from "reactstrap"
import { Button } from "@mui/material"
import _ from "lodash"
import QueryString from "query-string"
import {
  getCollectionEntities,
  updateCollectionEntity,
  deleteCollectionEntity,
  upsertCollectionEntity,
} from "../../appRedux/slices/zeroSearch"
import { getLocationData } from "../../appRedux/slices/location"
import { getLanguageData } from "../../appRedux/slices/uiConstants"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import EntityCard from "../../components/Discovery/entityCard"
import ValidatorForm, { Switch, MultiSelect } from "../ValidatorForm"
import { formatNumber } from "../../util/common"

class CollectionEntitiesList extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      previewModal: false,
      currentPageId: null,
      showEditEntityModal: false,
      filters: {
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        collectionId:
          this.props.queryParams && this.props.queryParams["collectionId"]
            ? this.props.queryParams["collectionId"]
            : "",
        collectionType:
          this.props.queryParams && this.props.queryParams["collectionType"]
            ? this.props.queryParams["collectionType"]
            : "",
        collectionFormat:
          this.props.queryParams && this.props.queryParams["collectionFormat"]
            ? this.props.queryParams["collectionFormat"]
            : "",
        elementType:
          this.props.queryParams && this.props.queryParams["elementType"]
            ? this.props.queryParams["elementType"]
            : "",
        elementId:
          this.props.queryParams && this.props.queryParams["elementId"]
            ? this.props.queryParams["elementId"]
            : "",
        filterType:
          this.props.queryParams && this.props.queryParams["type"]
            ? this.props.queryParams["type"]
            : "",
      },
    }
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(getCollectionEntities({ ...this.state.filters }))
      this.props.dispatch(getLocationData())
      this.props.dispatch(getLanguageData())
    }
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      this.props.dispatch(getCollectionEntities({ ...this.state.filters }))
    }
  }

  _onPageChange = (value, previewContent) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/manage/collection/entities/list?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        ),
          this.props.dispatch(
            getCollectionEntities({
              ...newFilters,
            })
          )
      }
    )
  }

  _onAdd = selectedElements => {
    this.setState({
      showEditEntityModal: true,
      action: "add",
      selectedElements,
    })
  }

  _onAddSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    let response
    const { collectionId, collectionType, elementType, collectionFormat } =
      this.state.filters
    const elementList = [...this.props.collectionEntities]
      .sort((a, b) => a.view_order - b.view_order)
      .map(a => a.element_uuid)
    const newAddedData = this.state.selectedElements.filter(
      arr1Item => !elementList.includes(arr1Item)
    )
    if (!_.isEmpty(this.state.selectedElements) && !_.isEmpty(newAddedData)) {
      response = await this.props.dispatch(
        upsertCollectionEntity({
          ...formData,
          collection_uuid: collectionId,
          collection_type: collectionType,
          element_type: elementType,
          collection_format: collectionFormat,
          entities: _.uniq(this.state.selectedElements),
        })
      )
    }
    this.setState({ showEditEntityModal: false })
  }

  _onEdit = entity => {
    let stateLocationList = []
    let cityLocationList = []
    this.props.locationList.map(location => {
      location.children.map(childrenLocation => {
        let childrenLocationObject = {}
        childrenLocationObject["label"] = childrenLocation.name
        childrenLocationObject["value"] = childrenLocation.dhTagId
        cityLocationList.push(childrenLocationObject)
      })
      let stateLocationObject = {}
      stateLocationObject["label"] = location.name
      stateLocationObject["value"] = location.dhTagId
      stateLocationList.push(stateLocationObject)
    })
    this.setState({
      stateLocationList,
      cityLocationList,
      showEditEntityModal: true,
      entity,
      action: "edit",
    })
  }

  _getDefaultLocations = () => {
    let locations = []
    this.state.entity.target.forEach(entity => {
      if (
        entity.lang_code === "default" &&
        entity.locations &&
        entity.locations.length > 0
      ) {
        entity.locations.forEach(location => {
          locations.push(location.location_id)
        })
      }
    })
    return locations
  }

  _getLocations = () => {
    const locationArray = this.props.locationList.reduce((acc, location) => {
      let cityArray = []
      if (location.children && location.children.length > 0) {
        cityArray = location.children.map(city => {
          return { label: city.name, value: city.dhTagId }
        })
      }
      return [
        ...acc,
        ...cityArray,
        { label: location.name, value: location.dhTagId },
      ]
    }, [])
    return locationArray
  }

  _onEditSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    await this.props.dispatch(
      updateCollectionEntity({
        ...formData,
        entity_uuid: this.state.entity.entity_uuid,
      })
    )
    this.setState({ showEditEntityModal: false })
  }

  _onDelete = async data => {
    await this.props.dispatch(deleteCollectionEntity(data))
  }

  _renderEditEntityModal = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>
            {this.state.action === "edit"
              ? "Edit Collection Entity"
              : "Add Collection Entities"}
          </CardTitle>
          <ValidatorForm
            onSubmit={
              this.state.action === "edit"
                ? this._onEditSubmit
                : this._onAddSubmit
            }
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm="12" md="6">
                <MultiSelect
                  label={"Languages"}
                  placeholderLabel="Languages"
                  field={"languages"}
                  className="creator-form"
                  defaultValue={
                    this.state.action === "edit" &&
                    this.state.entity &&
                    this.state.entity.target &&
                    this.state.entity.target.map(lng => lng.lang_code)
                  }
                  options={this.props.languageList.map(lang => {
                    return {
                      label: lang.constantLabel,
                      value: lang.constantVal,
                    }
                  })}
                  validations={["requiredArr"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm="12" md="6">
                <MultiSelect
                  label={"Location"}
                  placeholderLabel="Location"
                  field={"locations"}
                  className="creator-form"
                  defaultValue={
                    this.state.action === "edit" &&
                    this.state.entity &&
                    this.state.entity.target &&
                    this._getDefaultLocations()
                  }
                  options={this._getLocations()}
                />
              </Col>
              <Col sm={12} lg={2}>
                <Switch
                  label={"Is Editable"}
                  field={"is_editable"}
                  defaultValue={
                    this.state.action === "edit" &&
                    this.state.entity &&
                    this.state.entity.is_editable
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <div className="d-flex justify-content-end">
                  <Button type="submit" color="primary" variant="contained">
                    {this.state.action === "edit" ? "Update" : "Add"}
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() =>
                      this.setState({ showEditEntityModal: false })
                    }
                    className="mx-2"
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    this.setState({
      filters: newFilters,
    })
  }

  _searchPage = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/manage/collection/entities/list?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(getCollectionEntities({ ...this.state.filters }))
  }

  _filters = () => {
    return (
      <Row>
        <Col xs={12} sm={12} md={5} className="mb-3 d-flex">
          <Input
            defaultValue={this.state.filters["elementId"]}
            placeholder={"Element Id"}
            onChange={e =>
              this._handleFilterChange(e.target.value, "elementId")
            }
            allowClear={true}
            name="elementId"
            style={{ width: "100%" }}
          />
          <Button
            color="primary"
            className="mx-3 d-flex"
            onClick={() => {
              this._searchPage()
            }}
          >
            <i className="fa fa-search" />
            Search
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showEditEntityModal && (
          <React.Fragment>
            <Row className="d-flex justify-content-between my-2">
              <Col sm="12" md="6" className="my-1">
                <Button
                  onClick={() =>
                    this.props.history.push(
                      `/manage/collectionEntities?&collectionType=${this.state.filters.collectionType}&title=${this.state.filters.collectionId}`
                    )
                  }
                  color="primary"
                  variant="contained"
                  size="sm"
                  className="text-truncate"
                >
                  <i className="fa fa-angle-left mx-1" /> Back
                </Button>
              </Col>
              <Col
                className="d-flex my-1"
                sm="12"
                md="3"
                style={{ padding: "0px" }}
              >
                <Input
                  defaultValue={this.state.filters["elementId"]}
                  placeholder={"Element Id"}
                  onChange={e =>
                    this._handleFilterChange(e.target.value, "elementId")
                  }
                  allowClear={true}
                  name="elementId"
                  style={{ width: "210%" }}
                />
                <Button
                  color="primary"
                  onClick={() => {
                    this._searchPage()
                  }}
                  size="sm"
                  variant="contained"
                  className="mx-2 d-flex justify-content-center"
                  style={{ width: "90%" }}
                >
                  <i className="fa fa-search mx-1" />
                  Search
                </Button>
              </Col>
              <Col sm="4" md="1" className="my-1" style={{ padding: "0px" }}>
                <Button
                  onClick={() =>
                    this.setState({ showSeachAndSelectModal: true })
                  }
                  color="primary"
                  size="sm"
                  variant="contained"
                  className="text-truncate"
                  style={{ width: "90%" }}
                >
                  <i className="fa fa-plus mx-1" />
                  Add
                </Button>
              </Col>
              <Col sm="4" md="1" className="my-1" style={{ padding: "0px" }}>
                <Button
                  onClick={() =>
                    this.props.history.push(
                      `/reorder/collectionEntities?collectionId=${this.state.filters.collectionId}&collectionType=${this.state.filters.collectionType}&elementType=${this.state.filters.elementType}`
                    )
                  }
                  variant="contained"
                  color="primary"
                  size="sm"
                  className="text-truncate"
                  style={{ width: "90%" }}
                >
                  <i className="fa fa-sync mx-1" /> Reorder
                </Button>
              </Col>
              <Col sm="4" md="1" className="my-1" style={{ padding: "0px" }}>
                <Button
                  onClick={() => {
                    this.props.dispatch(
                      getCollectionEntities({ ...this.state.filters })
                    )
                  }}
                  color="primary"
                  variant="contained"
                  size="sm"
                  className="text-truncate"
                  style={{ width: "90%" }}
                >
                  <i className="fa fa-undo mx-1" /> Refresh
                </Button>
              </Col>
            </Row>
            {this.props.collectionEntities &&
              this.props.collectionEntities.length > 0 && (
                <div style={{ textAlign: "center", paddingBottom: "10px" }}>
                  <Tag color="geekblue">
                    Showing <b>{this.props.collectionEntities.length}</b>{" "}
                    of&nbsp;
                    <b>{formatNumber(this.props.total)}</b> Entities
                  </Tag>
                </div>
              )}
            <div className="discovery-card-container">
              {this.props.collectionEntities &&
                this.props.collectionEntities.map((entity, i) => {
                  return (
                    <EntityCard
                      data={entity}
                      locationList={this.props.locationList}
                      onEditClick={this._onEdit}
                      onDeleteClick={this._onDelete}
                    />
                  )
                })}
            </div>
            {this.props.collectionEntities &&
              this.props.collectionEntities.length > 0 && (
                <div className="d-flex justify-content-end">
                  <Pagination
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    onChange={this._onPageChange}
                    defaultCurrent={this.state.filters.currentPage}
                    total={this.props.total < 10000 ? this.props.total : 10000}
                    pageSize={this.state.filters.pageSize}
                    showSizeChanger={false}
                  />
                </div>
              )}
          </React.Fragment>
        )}
        {this.state.showEditEntityModal && this._renderEditEntityModal()}
        {this.state.showSeachAndSelectModal && (
          <SearchAndSelect
            type={this.state.filters.elementType}
            visible={this.state.showSeachAndSelectModal}
            onClose={() => this.setState({ showSeachAndSelectModal: false })}
            onAdd={arr => this._onAdd(arr)}
            element={this.state.element}
            selectedArr={_.uniq(
              this.props.collectionEntities.map(a => a.element_uuid)
            )}
            zoneId={
              this.state.filters.collectionType === "ZONE"
                ? this.state.filters.collectionId
                : undefined
            }
            filterType={this.state.filters.filterType}
          />
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    languageList: _.get(store, "uiConstants.languageList"),
    locationList: _.get(store, "locationReducer.locationList"),
    loading: _.get(store, "zeroSearch.loading"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    collectionEntities: _.get(store, "zeroSearch.collectionEntities"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    total: _.get(store, "zeroSearch.total"),
    refetch: _.get(store, "zeroSearch.refetchData"),
  }
}

export default connect(mapStateToProps)(CollectionEntitiesList)
