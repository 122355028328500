import Axios from "axios"
import toastr from "toastr"

export const requestGetDynamicForms = async ({ payload }) => {
  // const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
  // _.unset(payload, "currentPage")
  try {
    let data = await Axios.post(`/api/dsl/list`, {})
    return { data: data.data }
    return {
      data: [
        {
          name: "E_PRIVATE_MODE_BASELINE",
          forms: [
            {
              formName: "general",
              layout: [
                {
                  field_name: "enabled",
                  type: "boolean",
                  label: "Enabled",
                  value: true,
                },

                {
                  field_name: "supportedUserType",
                  label: "Supported user type",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "allowForClients",
                  label: "Allowed For Clients",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "includeUserSegment",
                  label: "Include User Segment",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "excludeUserSegment",
                  label: "Exlude User Segement",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "allowOnlyUserLangContent",
                  label: "Allow Only User Lang Content",
                  type: "boolean",
                },
                {
                  field_name: "includeLangs",
                  label: "Included Langs",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "user_seg_max_limit",
                  label: "User Seg Max Limit",
                  type: "object",
                  value: {
                    H: 550,
                    M: 450,
                    L: 375,
                  },
                },
                {
                  field_name: "allowedForHashcode",
                  label: "Allowed For Hashcode",
                  type: "header",
                },
                {
                  field_name: "allowedForHashcode.default",
                  label: "Default",
                  type: "array",
                  parent: "allowedForHashcode",
                },
                {
                  field_name: "allowedForSecHashcode",
                  label: "Allowed For Sec Hashcode",
                  type: "header",
                },
                {
                  field_name: "allowedForSecHashcode.default",
                  label: "Default",
                  type: "array",
                  parent: "allowedForSecHashcode",
                },
                {
                  field_name: "experiment",
                  label: "Experiment",
                  type: "header",
                },
                {
                  field_name: "experiment.name",
                  type: "string",
                  label: "Name",
                  parent: "experiment",
                  value: "hii3",
                },
                {
                  field_name: "experiment.description",
                  label: "Description",
                  type: "string",
                  parent: "experiment",
                  value: "hi2",
                },
              ],
            },
            {
              formName: "experiment_properties",
              layout: [
                {
                  field_name: "rankers",
                  label: "Rankers",
                  type: "array",
                  value: ["MTL_V2"],
                },
                {
                  field_name: "retrieval_sources",
                  label: "Retrieval Sources",
                  type: "array",
                  value: [
                    "coldstart_josh_interests_v1_curated_bharat_skits",
                    "coldstart_josh_interests_v1_curated_bharat_stunts",
                    "coldstart_josh_interests_v1_curated_bharat_clips",
                  ],
                },
                {
                  field_name: "ignore_retrieval_sources",
                  label: "Ignore Retrieval Sources",
                  type: "array",
                  value: [
                    "content_based_ppr_tamil",
                    "content_based_ppr_non_tamil",
                    "dnn_mtl_model_gt5_p90_v5_ta",
                  ],
                },
                {
                  field_name: "history_type",
                  type: "string",
                  label: "History Type",
                  value: "ts",
                },
                {
                  field_name: "retrieval_sources_config",
                  label: "Retrieval Sources Config",
                  type: "header",
                },
                {
                  field_name: "user_seg_max_limit",
                  label: "User Seg Max Limit",
                  type: "object",
                  value: {
                    coldstart_josh_interests_v1_curated_bharat_skits: {
                      allow_only_user_lang_content: true,
                      lang_configs: {
                        default: {
                          max_limit: 0.253,
                          enabled: true,
                        },
                      },
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          name: "E_PRIVATE_MODE_BASELINE2",
          forms: [
            {
              formName: "general",
              layout: [
                {
                  field_name: "enabled",
                  type: "boolean",
                  label: "Enabled",
                  value: true,
                },

                {
                  field_name: "supportedUserType",
                  label: "Supported user type",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "allowForClients",
                  label: "Allowed For Clients",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "includeUserSegment",
                  label: "Include User Segment",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "excludeUserSegment",
                  label: "Exlude User Segement",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "allowOnlyUserLangContent",
                  label: "Allow Only User Lang Content",
                  type: "boolean",
                },
                {
                  field_name: "includeLangs",
                  label: "Included Langs",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "user_seg_max_limit",
                  label: "User Seg Max Limit",
                  type: "object",
                  value: {
                    H: 550,
                    M: 450,
                    L: 375,
                  },
                },
                {
                  field_name: "allowedForHashcode",
                  label: "Allowed For Hashcode",
                  type: "header",
                },
                {
                  field_name: "allowedForHashcode.default",
                  label: "Default",
                  type: "array",
                  parent: "allowedForHashcode",
                },
                {
                  field_name: "allowedForSecHashcode",
                  label: "Allowed For Sec Hashcode",
                  type: "header",
                },
                {
                  field_name: "allowedForSecHashcode.default",
                  label: "Default",
                  type: "array",
                  parent: "allowedForSecHashcode",
                },
                {
                  field_name: "experiment",
                  label: "Experiment",
                  type: "header",
                },
                {
                  field_name: "experiment.name",
                  type: "string",
                  label: "Name",
                  parent: "experiment",
                  value: "hii3",
                },
                {
                  field_name: "experiment.description",
                  label: "Description",
                  type: "string",
                  parent: "experiment",
                  value: "hi2",
                },
              ],
            },
            {
              formName: "experiment",
              layout: [
                {
                  field_name: "enabled",
                  type: "boolean",
                  label: "Enabled",
                  value: true,
                },

                {
                  field_name: "supportedUserType",
                  label: "Supported user type",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "allowForClients",
                  label: "Allowed For Clients",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "includeUserSegment",
                  label: "Include User Segment",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "excludeUserSegment",
                  label: "Exlude User Segement",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "allowOnlyUserLangContent",
                  label: "Allow Only User Lang Content",
                  type: "boolean",
                },
                {
                  field_name: "includeLangs",
                  label: "Included Langs",
                  type: "array",
                  value: ["existing"],
                },
                {
                  field_name: "user_seg_max_limit",
                  label: "User Seg Max Limit",
                  type: "object",
                  value: {
                    H: 550,
                    M: 450,
                    L: 375,
                  },
                },
                {
                  field_name: "allowedForHashcode",
                  label: "Allowed For Hashcode",
                  type: "header",
                },
                {
                  field_name: "allowedForHashcode.default",
                  label: "Default",
                  type: "array",
                  parent: "allowedForHashcode",
                },
                {
                  field_name: "allowedForSecHashcode",
                  label: "Allowed For Sec Hashcode",
                  type: "header",
                },
                {
                  field_name: "allowedForSecHashcode.default",
                  label: "Default",
                  type: "array",
                  parent: "allowedForSecHashcode",
                },
                {
                  field_name: "experiment",
                  label: "Experiment",
                  type: "header",
                },
                {
                  field_name: "experiment.name",
                  type: "string",
                  label: "Name",
                  parent: "experiment",
                  value: "hii3",
                },
                {
                  field_name: "experiment.description",
                  label: "Description",
                  type: "string",
                  parent: "experiment",
                  value: "hi2",
                },
              ],
            },
          ],
        },
      ],
    }
  } catch (error) {
    throw error
  }
}

export const requestGetDynamicFormsByName = async ({ payload }) => {
  let data = [
    {
      name: "E_PRIVATE_MODE_BASELINE",
      forms: [
        {
          formName: "general",
          layout: [
            {
              field_name: "enabled",
              type: "boolean",
              label: "Enabled",
              value: true,
            },

            {
              field_name: "supportedUserType",
              label: "Supported user type",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "allowForClients",
              label: "Allowed For Clients",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "includeUserSegment",
              label: "Include User Segment",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "excludeUserSegment",
              label: "Exlude User Segement",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "allowOnlyUserLangContent",
              label: "Allow Only User Lang Content",
              type: "boolean",
            },
            {
              field_name: "includeLangs",
              label: "Included Langs",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "user_seg_max_limit",
              label: "User Seg Max Limit",
              type: "object",
              value: {
                H: 550,
                M: 450,
                L: 375,
              },
            },
            {
              field_name: "allowedForHashcode",
              label: "Allowed For Hashcode",
              type: "header",
            },
            {
              field_name: "allowedForHashcode.default",
              label: "Default",
              type: "array",
              parent: "allowedForHashcode",
            },
            {
              field_name: "allowedForSecHashcode",
              label: "Allowed For Sec Hashcode",
              type: "header",
            },
            {
              field_name: "allowedForSecHashcode.default",
              label: "Default",
              type: "array",
              parent: "allowedForSecHashcode",
            },
            {
              field_name: "experiment",
              label: "Experiment",
              type: "header",
            },
            {
              field_name: "experiment.name",
              type: "string",
              label: "Name",
              parent: "experiment",
              value: "hii3",
            },
            {
              field_name: "experiment.description",
              label: "Description",
              type: "string",
              parent: "experiment",
              value: "hi2",
            },
          ],
        },
        {
          formName: "experiment_properties",
          layout: [
            {
              field_name: "rankers",
              label: "Rankers",
              type: "array",
              value: ["MTL_V2"],
            },
            {
              field_name: "retrieval_sources",
              label: "Retrieval Sources",
              type: "array",
              value: [
                "coldstart_josh_interests_v1_curated_bharat_skits",
                "coldstart_josh_interests_v1_curated_bharat_stunts",
                "coldstart_josh_interests_v1_curated_bharat_clips",
              ],
            },
            {
              field_name: "history_type",
              type: "string",
              label: "History Type",
              value: "ts",
            },
            {
              field_name: "retrieval_sources_config",
              label: "Retrieval Sources Config",
              type: "header",
            },
            {
              field_name: "user_seg_max_limit",
              label: "User Seg Max Limit",
              type: "object",
              value: {
                H: 550,
                M: 450,
                L: 375,
              },
            },

            {
              field_name: "allowedForHashcode.default",
              label: "Default",
              type: "array",
              parent: "allowedForHashcode",
            },
            {
              field_name: "allowedForSecHashcode",
              label: "Allowed For Sec Hashcode",
              type: "header",
            },
            {
              field_name: "allowedForSecHashcode.default",
              label: "Default",
              type: "array",
              parent: "allowedForSecHashcode",
            },
            {
              field_name: "experiment",
              label: "Experiment",
              type: "header",
            },
            {
              field_name: "experiment.name",
              type: "string",
              label: "Name",
              parent: "experiment",
              value: "hii3",
            },
            {
              field_name: "experiment.description",
              label: "Description",
              type: "string",
              parent: "experiment",
              value: "hi2",
            },
          ],
        },
      ],
      layout: [
        {
          field_name: "enabled",
          type: "boolean",
          label: "Enabled",
          value: true,
        },

        {
          field_name: "supportedUserType",
          label: "Supported user type",
          type: "array",
          value: ["existing"],
        },
        {
          field_name: "allowForClients",
          label: "Allowed For Clients",
          type: "array",
          value: ["existing"],
        },
        {
          field_name: "includeUserSegment",
          label: "Include User Segment",
          type: "array",
          value: ["existing"],
        },
        {
          field_name: "excludeUserSegment",
          label: "Exlude User Segement",
          type: "array",
          value: ["existing"],
        },
        {
          field_name: "allowOnlyUserLangContent",
          label: "Allow Only User Lang Content",
          type: "boolean",
        },
        {
          field_name: "includeLangs",
          label: "Included Langs",
          type: "array",
          value: ["existing"],
        },
        {
          field_name: "user_seg_max_limit",
          label: "User Seg Max Limit",
          type: "object",
          value: {
            H: 550,
            M: 450,
            L: 375,
          },
        },
        {
          field_name: "allowedForHashcode",
          label: "Allowed For Hashcode",
          type: "header",
        },
        {
          field_name: "allowedForHashcode.default",
          label: "Default",
          type: "array",
          parent: "allowedForHashcode",
        },
        {
          field_name: "allowedForSecHashcode",
          label: "Allowed For Sec Hashcode",
          type: "header",
        },
        {
          field_name: "allowedForSecHashcode.default",
          label: "Default",
          type: "array",
          parent: "allowedForSecHashcode",
        },
        {
          field_name: "experiment",
          label: "Experiment",
          type: "header",
        },
        {
          field_name: "experiment.name",
          type: "string",
          label: "Name",
          parent: "experiment",
          value: "hii3",
        },
        {
          field_name: "experiment.description",
          label: "Description",
          type: "string",
          parent: "experiment",
          value: "hi2",
        },
      ],
    },
    {
      name: "E_PRIVATE_MODE_BASELINE2",
      forms: [
        {
          formName: "general",
          layout: [
            {
              field_name: "enabled",
              type: "boolean",
              label: "Enabled",
              value: true,
            },

            {
              field_name: "supportedUserType",
              label: "Supported user type",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "allowForClients",
              label: "Allowed For Clients",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "includeUserSegment",
              label: "Include User Segment",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "excludeUserSegment",
              label: "Exlude User Segement",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "allowOnlyUserLangContent",
              label: "Allow Only User Lang Content",
              type: "boolean",
            },
            {
              field_name: "includeLangs",
              label: "Included Langs",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "user_seg_max_limit",
              label: "User Seg Max Limit",
              type: "object",
              value: {
                H: 550,
                M: 450,
                L: 375,
              },
            },
            {
              field_name: "allowedForHashcode",
              label: "Allowed For Hashcode",
              type: "header",
            },
            {
              field_name: "allowedForHashcode.default",
              label: "Default",
              type: "array",
              parent: "allowedForHashcode",
            },
            {
              field_name: "allowedForSecHashcode",
              label: "Allowed For Sec Hashcode",
              type: "header",
            },
            {
              field_name: "allowedForSecHashcode.default",
              label: "Default",
              type: "array",
              parent: "allowedForSecHashcode",
            },
            {
              field_name: "experiment",
              label: "Experiment",
              type: "header",
            },
            {
              field_name: "experiment.name",
              type: "string",
              label: "Name",
              parent: "experiment",
              value: "hii3",
            },
            {
              field_name: "experiment.description",
              label: "Description",
              type: "string",
              parent: "experiment",
              value: "hi2",
            },
          ],
        },
        {
          formName: "experiment",
          layout: [
            {
              field_name: "enabled",
              type: "boolean",
              label: "Enabled",
              value: true,
            },

            {
              field_name: "supportedUserType",
              label: "Supported user type",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "allowForClients",
              label: "Allowed For Clients",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "includeUserSegment",
              label: "Include User Segment",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "excludeUserSegment",
              label: "Exlude User Segement",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "allowOnlyUserLangContent",
              label: "Allow Only User Lang Content",
              type: "boolean",
            },
            {
              field_name: "includeLangs",
              label: "Included Langs",
              type: "array",
              value: ["existing"],
            },
            {
              field_name: "user_seg_max_limit",
              label: "User Seg Max Limit",
              type: "object",
              value: {
                H: 550,
                M: 450,
                L: 375,
              },
            },
            {
              field_name: "allowedForHashcode",
              label: "Allowed For Hashcode",
              type: "header",
            },
            {
              field_name: "allowedForHashcode.default",
              label: "Default",
              type: "array",
              parent: "allowedForHashcode",
            },
            {
              field_name: "allowedForSecHashcode",
              label: "Allowed For Sec Hashcode",
              type: "header",
            },
            {
              field_name: "allowedForSecHashcode.default",
              label: "Default",
              type: "array",
              parent: "allowedForSecHashcode",
            },
            {
              field_name: "experiment",
              label: "Experiment",
              type: "header",
            },
            {
              field_name: "experiment.name",
              type: "string",
              label: "Name",
              parent: "experiment",
              value: "hii3",
            },
            {
              field_name: "experiment.description",
              label: "Description",
              type: "string",
              parent: "experiment",
              value: "hi2",
            },
          ],
        },
      ],
      layout: [
        {
          field_name: "enabled",
          type: "boolean",
          label: "Enabled",
          value: true,
        },

        {
          field_name: "supportedUserType",
          label: "Supported user type",
          type: "array",
          value: ["existing"],
        },
        {
          field_name: "allowForClients",
          label: "Allowed For Clients",
          type: "array",
          value: ["existing"],
        },
        {
          field_name: "includeUserSegment",
          label: "Include User Segment",
          type: "array",
          value: ["existing"],
        },
        {
          field_name: "excludeUserSegment",
          label: "Exlude User Segement",
          type: "array",
          value: ["existing"],
        },
        {
          field_name: "allowOnlyUserLangContent",
          label: "Allow Only User Lang Content",
          type: "boolean",
        },
        {
          field_name: "includeLangs",
          label: "Included Langs",
          type: "array",
          value: ["existing"],
        },
        {
          field_name: "user_seg_max_limit",
          label: "User Seg Max Limit",
          type: "object",
          value: {
            H: 1,
            M: 2,
            L: 3,
          },
        },
        {
          field_name: "allowedForHashcode",
          label: "Allowed For Hashcode",
          type: "header",
        },
        {
          field_name: "allowedForHashcode.default",
          label: "Default",
          type: "array",
          parent: "allowedForHashcode",
        },
        {
          field_name: "allowedForSecHashcode",
          label: "Allowed For Sec Hashcode",
          type: "header",
        },
        {
          field_name: "allowedForSecHashcode.default",
          label: "Default",
          type: "array",
          parent: "allowedForSecHashcode",
        },
        {
          field_name: "experiment",
          label: "Experiment",
          type: "header",
        },
        {
          field_name: "experiment.name",
          type: "string",
          label: "Name",
          parent: "experiment",
          value: "byee",
        },
        {
          field_name: "experiment.description",
          label: "Description",
          type: "string",
          parent: "experiment",
          value: "byee22",
        },
      ],
    },
  ]

  return { data: data.filter(item => item.name == payload) }
}
export const requestUpsertDynamicForm = async ({ payload }) => {
  try {
    let data = await Axios.post("/api/dynamicForms/upsert", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetActivity = async ({ payload }) => {
  try {
    const offset = payload["currentPage"] ? payload["currentPage"] : 1
    const limit = payload["pageSize"]
    payload["offset"] = offset
    payload["limit"] = limit
    _.unset(payload, "currentPage")
    _.unset(payload, "pageSize")
    let data = await Axios.post(
      `/api/issue/activity?offset=${offset}&limit=${limit}`,
      payload
    )
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestUpsertEvent = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/event/create`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateEvent = async ({ payload }) => {
  try {
    let data = await Axios.put(
      `/api/event/update/${payload.id}`,
      payload.data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestDeleteDynamicForm = async ({ payload }) => {
  try {
    let data = await Axios.delete(`/api/dynamicForm/delete?id=${payload.id}`)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
