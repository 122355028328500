import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import _, { map } from "lodash"
import { Card, CardBody } from "reactstrap"
import UserDrawer from "./userDrawer"
import ContentDrawer from "./contentDrawer"
import AudioDrawer from "./audioDrawer"
import ActivityDrawer from "./activityDrawer"
import CmsUserDrawer from "./cmsUserDrawer"
import TrendingDrawer from "./trendingDrawer"
import HashtagDrawer from "./hashtagDrawer"
import DiscoveryPageDrawer from "./discoveryPageDrawer"
import EntityDrawer from "./entityDrawer"
import EffectDrawer from "./effectDrawer"
import TemplateDrawer from "./templateDrawer"
import StickersSearchAndSelectDrawer from "./stickersSearchAndSelectDrawer"
import RoomDrawer from "./roomDrawer"
import IssueListDrawer from "./issueListDrawer"
import CameraAssetListDrawer from "./cameraAssetListDrawer"
import TaxonomyDrawer from "./taxonomyDrawer"
import ImageDrawer from "./ImageDrawer"
import TemplateSectionDrawer from "./TemplateSectionDrawer"
import CommunityLeaderboardDrawer from "./communityLeaderboardDrawer"
import { Button, Chip, Stack } from "@mui/material"
import { FaFilter } from "react-icons/fa"
import {
  userFilters,
  contentFilters,
  audioFilters,
  activityFilters,
  cmsUserFilters,
  trendingFilters,
  challengeFilters,
  contestFilters,
  hashtagFilters,
  discoveryPageFilters,
  entityFilters,
  commentFilters,
  effectFilters,
  templateFilters,
  stickersForSearchAndSelectFilters,
  roomFilters,
  bulkFilters,
  issueListFilter,
  userContentFilters,
  cameraAssetTemplateFilters,
  manageBannerFilters,
  manageChipsFilters,
  manageMusicArtistFilters,
  manageMusicLabelFilters,
  manageMusicPlaylistFilters,
  manageNamedCollectionFilters,
  manageWebGamesFilters,
  manageWebviewBannerFilters,
  manageGiftsFilters,
  manageGiftCollectionsFilters,
  packageFilters,
  manageGiftCollectionEntityFilters,
  packageCollectionFilters,
  packageCollectionEntityFilters,
  cameraAssetFilters,
  taxonomyFilters,
  imageFilters,
  zoneFilters,
  socialFilters,
  templateSectionFilters,
  memeFilters,
  manageAdsConfigFilters,
  manageAdsProfileFilters,
  communityLeaderboardFilters,
  zoneImageFilters,
  zoneContentFilters,
  zoneSocialFilters,
  brandLogoFilters,
  agencyFilters,
  listingsFilters,
  embeddingFilters,
  panKycFilters,
  kycDashFilters,
  curationFilters,
  storiesFilters,
  audioStoriesCollectionFilters,
  seriesFilters,
  blockedContentFilters,
  joshPaymentAudit,
  audioCallingFilters,
  creatorStatsFilters,
  audioAgencyFilters,
} from "../../constants/uiConstants"
import "react-datepicker/dist/react-datepicker.css"
import ChallengeDrawer from "./challengeDrawer"
import ContestDrawer from "./contestDrawer"
import CommentDrawer from "./commentDrawer"
import CameraAssetTemplateDrawer from "./cameraAssetTemplateDrawer"
import EntitiesDrawer from "./entitiesDrawer"
import ZoneDrawer from "./zoneDrawer"
import SocialDrawer from "./socialDrawer"
import MemeDrawer from "./MemeDrawer"
import AdsConfigDrawer from "./adsConfigDrawer"
import AdsProfileDrawer from "./adsProfileDrawer"
import BrandLogoDrawer from "./brandLogoDrawer"
import FeedEntityDrawer from "./feedEntityDrawer"
import AgencyLimitDrawer from "./agencyLimitDrawer"
import ListingsDrawer from "./listingsDrawer"
import EmbeddingDrawer from "./embeddingDrawer"
import PanKycDrawer from "./panKycDrawer"
import DuplicateDrawer from "./duplicateDrawer"
import CurationDrawer from "./curationDrawer"
import AudioStoriesDrawer from "./audioStoriesDrawer"
import AudioStoriesCollectionDrawer from "./audioStoriesCollectionDrawer"
import AudioSeriesDrawer from "./audioSeriesDrawer"
import BlockedContentDrawer from "./blockedContentDrawer"
import JoshPaymentAuditDrawer from "./joshPaymentAuditDrawer"
import AudioCallingDrawer from "./audioCallingDrawer"
import CreatorStatsDrawer from "./CreatorStatsDrawer"
import LiveCallingAgencyDrawer from "./LiveCallingAgencyDrawer"

const _getConstFilters = (type, props) => {
  switch (type) {
    case "user":
      return userFilters
    case "content":
      return contentFilters
    case "duplicate":
      return contentFilters
    case "taxonomy":
      return taxonomyFilters
    case "audio":
      return audioFilters
    case "cmsUser":
      return cmsUserFilters
    case "trending":
      return trendingFilters
    case "activity":
      return activityFilters
    case "challenge":
      return challengeFilters
    case "contest":
      return contestFilters
    case "hashtag":
      return hashtagFilters
    case "discoveryPage":
      return discoveryPageFilters
    case "entity":
      return entityFilters
    case "comment":
      return commentFilters
    case "effect":
      return effectFilters
    case "template":
      return templateFilters
    case "stickerForSearchAndSelect":
      return stickersForSearchAndSelectFilters
    case "rooms":
      return roomFilters
    case "bulk":
      return bulkFilters
    case "issueList":
      return issueListFilter
    case "user_content":
      return userContentFilters
    case "zone_feed_content":
      return zoneContentFilters
    case "zone_feed_image":
      return zoneImageFilters
    case "zone_feed_social":
      return zoneSocialFilters
    case "camera_asset_template":
      return cameraAssetTemplateFilters
    case "manage_banner":
      return manageBannerFilters
    case "manage_chips":
      return manageChipsFilters
    case "manage_music_artist":
      return manageMusicArtistFilters
    case "manage_music_label":
      return manageMusicLabelFilters
    case "manage_music_playlist":
      return manageMusicPlaylistFilters
    case "manage_named_collection":
      return manageNamedCollectionFilters
    case "manage_web_games":
      return manageWebGamesFilters
    case "manage_webview_banner":
      return manageWebviewBannerFilters
    case "manage_gifts":
      return manageGiftsFilters
    case "manage_gift_collections":
      return manageGiftCollectionsFilters
    case "manage_package":
      return packageFilters
    case "manage_gift_collection_entity":
      return manageGiftCollectionEntityFilters
    case "manage_package_collections":
      return packageCollectionFilters
    case "manage_package_collection_entity":
      return packageCollectionEntityFilters
    case "camera_asset":
      return cameraAssetFilters
    case "image":
      return imageFilters
    case "zone":
      return zoneFilters
    case "social":
      return socialFilters
    case "manage_template_section":
      return templateSectionFilters
    case "meme":
      return memeFilters
    case "listings":
      return listingsFilters
    case "manage_ads_config":
      return manageAdsConfigFilters
    case "manage_ads_profile":
      return manageAdsProfileFilters
    case "community_leaderboard":
      return communityLeaderboardFilters
    case "brand_logo":
      return brandLogoFilters
    case "agencylimit":
      return agencyFilters
    case "embedding":
      return embeddingFilters
    case "panKyc":
      return panKycFilters
    case "KYC":
      return panKycFilters
    case "curation":
      return curationFilters
    case "audio_stories":
      return storiesFilters
    case "audio_live_calling":
      return audioCallingFilters
    case "audio_stories_collection":
      return audioStoriesCollectionFilters
    case "audio_series":
      return seriesFilters
    case "blocked_content":
      return blockedContentFilters
    case "josh_payment_audit":
      return joshPaymentAudit
    case "creator_stats":
      return creatorStatsFilters
    case "agency_stats":
      return audioAgencyFilters
    default:
      return props.allowedFilters
  }
}

const Chips = props => {
  const filterClass = props.showFilters ? "d-block" : "d-none"
  const constFilters = _getConstFilters(props.type, props)

  const _getDrawer = () => {
    switch (props.type) {
      case "user":
        return (
          <div>
            <UserDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              cityLocationList={props.cityLocationList || []}
              stateLocationList={props.stateLocationList || []}
            />
          </div>
        )
      case "user_content":
        return (
          <div>
            <ContentDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              callFrom="user_content"
              // toggleAdvancedFilters={props.toggleAdvancedFilters}
              // showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "zone_feed_content":
      case "zone_feed_image":
      case "zone_feed_social":
        return (
          <div>
            <FeedEntityDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              // callFrom="zone_content"
              // toggleAdvancedFilters={props.toggleAdvancedFilters}
              // showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      // case "zone_image":
      //   return (
      //     <div>
      //       <ImageDrawer
      //         filters={props.filters}
      //         handleFilterChange={props.handleFilterChange}
      //         currentUser={props.currentUser}
      //         // toggleAdvancedFilters={props.toggleAdvancedFilters}
      //         // showAdvancedFilters={props.showAdvancedFilters}
      //         // allZoneList={props.allZoneList}
      //         callFrom="zone_image"
      //       />
      //     </div>
      //   )
      // case "zone_social":
      //   return (
      //     <div>
      //       <SocialDrawer
      //         filters={props.filters}
      //         handleFilterChange={props.handleFilterChange}
      //         currentUser={props.currentUser}
      //         // toggleAdvancedFilters={props.toggleAdvancedFilters}
      //         // showAdvancedFilters={props.showAdvancedFilters}
      //         // allZoneList={props.allZoneList}
      //         callFrom="zone_social"
      //       />
      //     </div>
      //   )
      case "content":
        return (
          <div>
            <ContentDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              allZoneList={props.allZoneList}
            />
          </div>
        )
      case "blocked_content":
        return (
          <div>
            <BlockedContentDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              allZoneList={props.allZoneList}
            />
          </div>
        )
      case "duplicate":
        return (
          <div>
            <DuplicateDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              allZoneList={props.allZoneList}
            />
          </div>
        )
      case "taxonomy":
        return (
          <div>
            <TaxonomyDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              deskOptions={props.deskOptions}
              agencyList={props.agencyList}
            />
          </div>
        )
      case "activity":
        return (
          <div>
            <ActivityDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              moderatorList={props.moderatorList}
            />
          </div>
        )
      case "audio":
        return (
          <div>
            <AudioDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "trending":
        return (
          <div>
            <TrendingDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "hashtag":
        return (
          <div>
            <HashtagDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "entity":
        return (
          <div>
            <EntityDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "effect":
        return (
          <div>
            <EffectDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "template":
        return (
          <div>
            <TemplateDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "discoveryPage":
        return (
          <div>
            <DiscoveryPageDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "stickerForSearchAndSelect":
        return (
          <div>
            <StickersSearchAndSelectDrawer
              mimeTypes={props.mimeTypes}
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "cmsUser":
        return (
          <div>
            <CmsUserDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "challenge":
        return (
          <div>
            <ChallengeDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "contest":
        return (
          <div>
            <ContestDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "camera_asset_template":
        return (
          <div>
            <CameraAssetTemplateDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "manage_banner":
      case "manage_chips":
      case "manage_music_artist":
      case "manage_music_label":
      case "manage_music_playlist":
      case "manage_named_collection":
      case "manage_web_games":
      case "manage_webview_banner":
      case "manage_gifts":
      case "manage_gift_collections":
      case "manage_package":
      case "manage_gift_collection_entity":
      case "manage_package_collections":
      case "manage_package_collection_entity":
        return (
          <div>
            <EntitiesDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              filterKeys={props.filterKeys}
            />
          </div>
        )
      case "manage_template_section":
        return (
          <div>
            <TemplateSectionDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              filterKeys={props.filterKeys}
            />
          </div>
        )
      case "meme":
        return (
          <div>
            <MemeDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "listings":
        return (
          <div>
            <ListingsDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
            />
          </div>
        )
      case "rooms":
        return (
          <div>
            <RoomDrawer
              moderatorList={props.moderatorList}
              handleFilterChange={props.handleFilterChange}
              filters={props.filters}
            />
          </div>
        )
      case "comment":
        return (
          <div>
            <CommentDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              moderatorList={props.moderatorList}
            />
          </div>
        )
      case "issueList":
        return (
          <div>
            <IssueListDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              moderatorList={props.moderatorList}
            />
          </div>
        )
      case "camera_asset":
        return (
          <div>
            <CameraAssetListDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              moderatorList={props.moderatorList}
            />
          </div>
        )
      case "image":
        return (
          <div>
            <ImageDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              allZoneList={props.allZoneList}
            />
          </div>
        )
      case "zone":
        return (
          <div>
            <ZoneDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              typeList={props.typeList}
              subTypeList={props.subTypeList}
              allZoneList={props.allZoneList}
              hideTypeFilter={props.hideTypeFilter}
            />
          </div>
        )
      case "social":
        return (
          <div>
            <SocialDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              allZoneList={props.allZoneList}
            />
          </div>
        )
      case "manage_ads_config":
        return (
          <div>
            <AdsConfigDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              // filterKeys={props.filterKeys}
            />
          </div>
        )
      case "manage_ads_profile":
        return (
          <div>
            <AdsProfileDrawer
              filters={props.filters}
              handleFilterChange={props.handleFilterChange}
              currentUser={props.currentUser}
              toggleAdvancedFilters={props.toggleAdvancedFilters}
              showAdvancedFilters={props.showAdvancedFilters}
              // filterKeys={props.filterKeys}
            />
          </div>
        )
      case "community_leaderboard":
        return (
          <CommunityLeaderboardDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
          />
        )
      case "brand_logo":
        return (
          <BrandLogoDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
          />
        )

      case "agencylimit":
        return (
          <AgencyLimitDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
          />
        )
      case "embedding":
        return (
          <EmbeddingDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
          />
        )
      case "panKyc":
        return (
          <PanKycDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
            dash={props.dash}
          />
        )
      case "curation":
        return (
          <CurationDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
            interestData={props.interestData}
          />
        )
      case "audio_stories":
        return (
          <AudioStoriesDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
            interestData={props.interestData}
          />
        )
      case "audio_live_calling":
        return (
          <AudioCallingDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
            interestData={props.interestData}
          />
        )
      case "audio_stories_collection":
        return (
          <AudioStoriesCollectionDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
            interestData={props.interestData}
          />
        )
      case "audio_series":
        return (
          <AudioSeriesDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
            interestData={props.interestData}
          />
        )
      case "KYC":
        return (
          <PanKycDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
            dash={props.dash}
          />
        )
      case "josh_payment_audit":
        return (
          <JoshPaymentAuditDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
          />
        )
      case "creator_stats":
        return (
          <CreatorStatsDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
            interestData={props.interestData}
          />
        )
      case "agency_stats":
        return (
          <LiveCallingAgencyDrawer
            filters={props.filters}
            handleFilterChange={props.handleFilterChange}
            currentUser={props.currentUser}
            toggleAdvancedFilters={props.toggleAdvancedFilters}
            showAdvancedFilters={props.showAdvancedFilters}
            interestData={props.interestData}
          />
        )
      default:
        return props.drawer()
    }
  }

  let appliedFilters = 0
  return (
    <div className="filter-dropdown">
      <Card>
        <CardBody>
          <div
            className="d-flex align-items-center flex-wrap"
            onClick={e => {
              props.toggleFilters(!props.showFilters)
            }}
          >
            {Object.keys(constFilters).map(filter => {
              const filterValue = "" + props.filters[filter]
              if (
                filterValue !== "null" &&
                filterValue !== "undefined" &&
                filterValue !== ""
              ) {
                appliedFilters++
                const filterLable = constFilters[filter].valueMap
                  ? constFilters[filter].valueMap[props.filters[filter]]
                  : props.filters[filter]

                return (
                  <Stack direction="row" spacing={2}>
                    <Chip
                      label={`${constFilters[filter].filterName}: ${filterLable}`}
                      className="m-1"
                      onDelete={() => props.removeFilter(filter)}
                    />
                  </Stack>
                )
              }
            })}
            <Button
              variant="contained"
              onClick={() => props.toggleFilters(!props.showFilters)}
              startIcon={<FaFilter />}
              size="small"
              className="mx-1"
            >
              More Filters
            </Button>
          </div>
        </CardBody>
        <div
          className={`filter-dropdown-content ${filterClass}`}
          style={{ background: "white" }}
        >
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => props.search()}
              role="button"
              color="success"
              variant="contained"
              size="small"
            >
              Apply
            </Button>
          </div>
          {props.showFilters && _getDrawer()}
        </div>
      </Card>
    </div>
  )
}

Chips.propTypes = {
  showFilters: PropTypes.bool,
  drawer: PropTypes.func,
  type: PropTypes.string,
  search: PropTypes.func,
  removeFilter: PropTypes.func,
  handleFilterChange: PropTypes.func,
  toggleFilters: PropTypes.func,
  allowedFilters: PropTypes.object,
  filters: PropTypes.object,
}

function mapStateToProps(store) {
  return {
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(Chips)
