import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button } from "@mui/material"
import { MdAdd } from "react-icons/md"
import { getAudioStoryCollectionData } from "clientServices/audioStories"
import StoriesCard from "./storiesCard"
import { sleep } from "util/common"
import AudioStoryCollectionForm from "./audioStoryCollectionForm"
import {
  NO_PERMISSION_MESSAGE,
  audioStoriesCollectionFilters,
} from "constants/uiConstants"
import toastr from "toastr"
import Chips from "components/CommonFilter/chips"

class ManageAudioStoryCollection extends ErrorBoundComponent {
  state = {
    showSeriesForm: false,
    audioStorieCollectionData: [],
    currentSeries: undefined,
    filters: {
      collectionId: "",
    },
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      try {
        this.setState({
          loading: true,
        })
        let audioStorieCollectionData = await getAudioStoryCollectionData()
        this.setState({
          audioStorieCollectionData,
        })
      } catch (error) {
        toastr.error("Error in fetching data...")
      } finally {
        this.setState({
          loading: false,
        })
      }
    }
  }

  fetchAudioStories = async () => {
    await sleep(2000)
    let audioStorieCollectionData = await getAudioStoryCollectionData()
    this.setState({
      audioStorieCollectionData,
      showSeriesForm: false,
    })
  }
  onEditClick = (action, data) => {
    if (
      !this.props.currentUser?.permissions?.includes(
        "EDIT_AUDIO_STORY_COLLECTION"
      )
    ) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({
      currentSeries: data,
      formAction: action,
      showSeriesForm: true,
    })
  }
  onCreateClick = (action, data) => {
    this.setState({
      currentSeries: {},
      formAction: action,
      showSeriesForm: true,
    })
  }
  onCancel = () => {
    this.setState({
      currentSeries: undefined,
      formAction: undefined,
      showSeriesForm: false,
    })
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this._keyDownHandler)
  }
  _closeFilters = () => {
    this._searchStories()
    this.setState({
      showAdvancedFilter: false,
      showFilters: false,
    })
  }
  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: audioStoriesCollectionFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      async () => {
        let audioStorieCollectionData = await getAudioStoryCollectionData()
        this.setState({
          audioStorieCollectionData,
          showSeriesForm: false,
        })
      }
    )
  }
  _searchStories = async () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }

    let audioStorieCollectionData = await getAudioStoryCollectionData(
      _.deepClean(this.state.filters)
    )
    this.setState({
      audioStorieCollectionData,
      showSeriesForm: false,
    })
  }
  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }
  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }
  render() {
    return (
      <Auxiliary
        loading={this.props.loading || this.state.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          {this.state.showSeriesForm && (
            <AudioStoryCollectionForm
              formAction={this.state.formAction}
              onCancel={this.onCancel}
              audio={this.state.currentSeries}
              fetchAudioStories={this.fetchAudioStories}
            ></AudioStoryCollectionForm>
          )}

          {!this.state.showSeriesForm && (
            <>
              <Chips
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFilterChange}
                type="audio_stories_collection"
                toggleFilters={this._toggleFilters}
              />
              <div className="d-flex justify-content-end">
                <Button
                  variant="contained"
                  onClick={() => this.onCreateClick("create", {})}
                  startIcon={<MdAdd />}
                >
                  Create
                </Button>
              </div>

              <Row>
                <Col lg="2" md="12">
                  <div className="d-flex justify-content-end mb-3"></div>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(256px, 1fr))",
                      gridGap: "2rem",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.state.audioStorieCollectionData.map(item => {
                      return (
                        <StoriesCard
                          audio={item}
                          onEditClick={this.onEditClick}
                        ></StoriesCard>
                      )
                    })}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageAudioStoryCollection)
