import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Input, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import dayjs from "dayjs"
import moment from "moment"

const CreatorStatsDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Creator ID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["userUUID"]}
                  placeholder={"Creator ID"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value.trim(), "userUUID")
                  }
                  allowClear={true}
                  name="userUUID"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Created Date</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <DatePicker
                  defaultValue={
                    props.filters["createdDateFrom"]
                      ? dayjs.unix(
                          props.filters["createdDateFrom"] / 1000,
                          "YYYY-MM-DD"
                        )
                      : null
                  }
                  format="YYYY-MM-DD HH:mm"
                  showTime
                  placeholder="From Date"
                  onChange={(date, dateString) => {
                    props.handleFilterChange(
                      !_.isEmpty(dateString)
                        ? moment(dateString).format("x")
                        : null,
                      "createdDateFrom"
                    )
                  }}
                  style={{ width: "100%" }}
                />
                <DatePicker
                  defaultValue={
                    props.filters["createdDateTo"]
                      ? dayjs.unix(
                          props.filters["createdDateTo"] / 1000,
                          "YYYY-MM-DD"
                        )
                      : null
                  }
                  format="YYYY-MM-DD HH:mm"
                  showTime
                  placeholder="To Date"
                  onChange={(date, dateString) => {
                    props.handleFilterChange(
                      !_.isEmpty(dateString)
                        ? moment(dateString).format("x")
                        : null,
                      "createdDateTo"
                    )
                  }}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(CreatorStatsDrawer)
