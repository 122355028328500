import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const dslUpdate = async payload => {
  try {
    const response = await Axios.post(`/api/dsl/upload`, payload)
    toastr.success("Action Successful!")
  } catch (error) {
    return error
  }
}
