function removeNullAndUndefinedFromArray(arr) {
  return _.filter(arr, value => !_.isNil(value))
}

module.exports = {
  msToHMS(ms) {
    // 1- Convert to seconds:
    let seconds = ms / 1000

    // 2- Extract days:
    const days = Math.floor(seconds / (3600 * 24)) // 86,400 seconds in a day
    seconds = seconds % (3600 * 24) // remaining seconds after extracting days

    // 3- Extract hours:
    const hours = Math.floor(seconds / 3600) // 3,600 seconds in an hour
    seconds = seconds % 3600 // remaining seconds after extracting hours

    const minutes = Math.floor(seconds / 60) // 60 seconds in a minute

    // 5- Keep only seconds not extracted to minutes:
    seconds = (seconds % 60).toFixed(0)

    // Pad hours, minutes, and seconds with leading zeros if necessary
    const pad = n => (n < 10 ? "0" + n : n)

    // Build the time string
    let timeString = pad(hours) + ":" + pad(minutes) + ":" + pad(seconds)

    // Include days if there are any
    if (days > 0) {
      timeString = days + "D " + timeString
    }

    return timeString
  },
  queryStringToJSON(qs) {
    var pairs = qs.split("&")
    var result = {}

    pairs.forEach(function (p) {
      var pair = p.split("=")
      var key = pair[0]
      var value = decodeURIComponent(pair[1] || "")
      if (result[key]) {
        if (Object.prototype.toString.call(result[key]) === "[object Array]") {
          result[key].push(value)
        } else {
          result[key] = [result[key], value]
        }
      } else {
        result[key] = value
      }
    })
    return JSON.parse(JSON.stringify(result))
  },

  formatNumber(num, afterDecimal = 2) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(afterDecimal).replace(/\.0$/, "") + "B"
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(afterDecimal).replace(/\.0$/, "") + "M"
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(afterDecimal).replace(/\.0$/, "") + "K"
    }
    return num
  },

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  },

  isJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  },

  getColor(value) {
    //value from 0 to 1
    const hue = ((1 - value) * 120).toString(10)
    return ["hsl(", hue, ", 100%, 50%)"].join("")
  },

  removeEmptyValuesFromObject(obj) {
    return _.transform(
      obj,
      (result, value, key) => {
        // Check for null or undefined value
        if (
          _.isNil(value) ||
          (typeof value === "string" && value.trim() === "")
        ) {
          // Explicitly return undefined to skip adding the key-value pair
          return
        }

        // Handle arrays recursively
        if (_.isArray(value)) {
          result[key] = removeNullAndUndefinedFromArray(value)
        } else {
          result[key] = value
        }
      },
      {}
    ) // Provide an empty object as the initial accumulator
  },

  millisecondsToHHMMSS(ms) {
    if (!ms) return `00:00:00`
    let totalSeconds = Math.floor(ms / 1000)
    let hours = Math.floor(totalSeconds / 3600)
    let minutes = Math.floor((totalSeconds % 3600) / 60)
    let seconds = totalSeconds % 60

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },
}
