import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
const dynamicFormSlice = createSlice({
  name: "dynamicForm",
  initialState: {
    loading: false,
    dynamicFormData: {},
  },

  reducers: {
    getDynamicForms(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getDynamicFormsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        dynamicFormData: payload,
      }
    },
    getDynamicFormsByName(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getDynamicFormsByNameSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        dynamicFormData: payload,
      }
    },
    createDynamicForm(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createDynamicFormSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: true,
        createStatus: payload.status,
      }
    },
    upsertDynamicForm(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertDynamicFormSuccess(state, { payload }) {
      let cloneData = _.cloneDeep(payload.alldata)
      cloneData.data.map(item => {
        if (item.name == payload.formName) {
          item.forms[payload.currentForm]["layout"] = payload.layout
        }
      })
      return {
        ...state,
        loading: false,
        dynamicFormData: cloneData,
      }
    },
    getActivity(state) {
      return {
        ...state,
        loadingActivity: true,
      }
    },
    getActivitySuccess(state, { payload }) {
      return {
        ...state,
        loadingActivity: false,
        activityList: payload.result.data || [],
        totalActivity: payload.result.count || 0,
      }
    },
    deleteDynamicForm(state) {
      return {
        ...state,
        loading: true,
      }
    },
    deleteDynamicFormSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
      }
    },
    resetState() {
      return {
        loading: false,
        data: {},
      }
    },
  },
})

export const {
  createEvent,
  createEventSuccess,
  getDynamicForms,
  getDynamicFormsSuccess,
  getDynamicFormsByName,
  getDynamicFormsByNameSuccess,
  getActivity,
  upsertDynamicForm,
  upsertDynamicFormSuccess,
  getActivitySuccess,
  deleteDynamicForm,
  deleteDynamicFormSuccess,
  resetState,
} = dynamicFormSlice.actions

export default dynamicFormSlice.reducer
