import { downloadInvoice, getAgencyInvoice } from "appRedux/slices/agency"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Card, Col, Row } from "reactstrap"
import Auxiliary from "util/Auxiliary"
import { formatNumber } from "util/common"
import {
  Table,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Button,
} from "@mui/material"
import { Pagination } from "antd"
import { MdDownload, MdEdit } from "react-icons/md"
import moment from "moment"

export default function AgencyInvoice(props) {
  const dispatch = useDispatch()
  const [filters, setFilters] = useState({
    pageSize:
      props.queryParams && props.queryParams["pageSize"]
        ? props.queryParams["pageSize"]
        : 10,
    currentPage:
      props.queryParams && props.queryParams["currentPage"]
        ? props.queryParams["currentPage"]
        : 1,
  })
  const [fileType, setFileType] = useState("pdf")

  const { invoiceData, loading, creatorsData, creatorsTotal, invoiceFile } =
    useSelector(state => state.agencyStats)

  const {
    currentUser: { data },
  } = useSelector(state => state.currentUser)

  useEffect(() => {
    dispatch(getAgencyInvoice(data.partnerId))
  }, [])

  useEffect(() => {
    if (invoiceFile) {
      downloadPdf(invoiceFile)
    }
  }, invoiceFile)

  const handlePageChange = () => {}

  const downloadPdf = invoiceFile => {
    const anchorElement = document.createElement("a")
    anchorElement.href = `data:application/${fileType};base64,${invoiceFile.data}`
    anchorElement.download = `${invoiceFile.invoiceId}.${fileType}`
    anchorElement.click()
  }

  const handleDownload = (data, fileType) => {
    setFileType(fileType)
    dispatch(downloadInvoice(data))
  }

  return (
    <Auxiliary loading={loading}>
      <Row>
        <Col lg="6">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Total Diamonds Earned</h3>
              <h4>{formatNumber(invoiceData?.billing_period)}</h4>
            </div>
          </Card>
        </Col>
        <Col lg="6">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Total Redeemable Diamonds</h3>
              <h4>{formatNumber(invoiceData?.total_redeemable_diamond)}</h4>
            </div>
          </Card>
        </Col>
      </Row>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="large" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Invoice Id</b>
                </TableCell>
                <TableCell>
                  <b>Billing Period</b>
                </TableCell>
                <TableCell>
                  <b>Created Date</b>
                </TableCell>
                <TableCell>
                  <b>Partner Report</b>
                </TableCell>
                <TableCell>
                  <b>Creators Report</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData &&
                invoiceData.length > 0 &&
                invoiceData?.map(row => {
                  return (
                    <TableRow style={{ cursor: "pointer" }}>
                      <TableCell>{row.invoice_id}</TableCell>
                      <TableCell>
                        {row.billing_period.split("_").join(" ")}
                      </TableCell>
                      <TableCell>
                        {moment(parseInt(row.created_date)).format(
                          "DD-MM-YYYY - HH:MM:SS"
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleDownload(
                              {
                                location: row.location.partner_report_name,
                                invoiceId: row.invoice_id,
                              },
                              "pdf"
                            )
                          }
                        >
                          <MdDownload />
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleDownload(
                              {
                                location: row.location.creators_report_name,
                                invoiceId: row.invoice_id,
                              },
                              "csv"
                            )
                          }
                        >
                          <MdDownload />
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="d-flex justify-content-end pb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={handlePageChange}
            defaultCurrent={filters.currentPage}
            total={creatorsTotal}
            pageSize={filters.pageSize}
            showSizeChanger={false}
          />
        </div>
      </Paper>
    </Auxiliary>
  )
}
