import Axios from "axios"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

export const getRatingList = async payload => {
  try {
    let { data } = await Axios.post(`/api/rating/list`, payload)

    return data?.result ? data.result : []
  } catch (error) {
    throw error
  }
}

export const upsertRating = async payload => {
  try {
    toastr.info("Action in progress...")
    let { data } = await Axios.post(`/api/rating/upsert`, payload)
    toastr.clear()
    toastr.success("Action successfull...")
    return data?.result ? data.result : []
  } catch (error) {
    toastr.clear()
    toastr.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "Error in upserting data"
    )
    throw error
  }
}
