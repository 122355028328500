import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { resetState } from "appRedux/slices/zone"
import _ from "lodash"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button } from "@mui/material"
import { Tabs } from "antd"
import UpsertRating from "./upsertRating"
import RatingList from "./ratingList"
import toastr from "toastr"

class Rating extends ErrorBoundComponent {
  state = {
    isUpsertRating: false,
    isListRating: true,
    currentRow: undefined,
    action: "create",
    listData: [],
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _toggleThemeForm = () => {
    this.setState({
      currentRow: undefined,
      isUpsertRating: !this.state.isUpsertRating,
      isListRating: !this.state.isListRating,
      action: "create",
    })
  }

  setCurrentRow = row => {
    this.setState({
      currentRow: row,
      isUpsertRating: !this.state.isUpsertRating,
      isListRating: !this.state.isListRating,
      action: "update",
    })
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          {this.state.isListRating && (
            <div className="d-flex justify-content-end">
              <Button
                variant="contained"
                onClick={() => {
                  let activeCount = 0
                  this.state.listData.forEach(item => {
                    if (item.status === "ACTIVE") activeCount++
                  })

                  if (activeCount >= 5) {
                    toastr.info(
                      "Only 5 items can be in ACTIVE state at a time, please disable another issues..."
                    )
                    return
                  }
                  this._toggleThemeForm()
                }}
                style={{ marginBottom: "10px" }}
              >
                Create
              </Button>
            </div>
          )}
          {this.state.isListRating && (
            <RatingList
              setCurrentRow={this.setCurrentRow}
              setListData={listData => {
                this.setState({
                  listData,
                })
              }}
            />
          )}
          {this.state.isUpsertRating && (
            <UpsertRating
              toggleCreateForm={this._toggleThemeForm}
              currentRow={this.state.currentRow}
              action={this.state.action}
            />
          )}
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(Rating)
