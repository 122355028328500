import { createSlice } from "@reduxjs/toolkit"

const agencySlice = createSlice({
  name: "agencyStats",
  initialState: {
    agencyData: {},
    creatorsData: [],
    creatorsTotal: 0,
    invoiceFile: null,
  },
  reducers: {
    getAgencyStats(state) {
      return {
        ...state,
        loading: true,
      }
    },
    getAgencyCreators(state) {
      return {
        ...state,
        loading: true,
      }
    },
    setAgencyStats(state, { payload }) {
      return {
        ...state,
        loading: false,
        agencyData: payload.data[0],
      }
    },
    setAgencyCreators(state, { payload }) {
      return {
        ...state,
        loading: false,
        creatorsData: payload.data,
        creatorsTotal: payload.total,
      }
    },
    getAgencyInvoice(state) {
      return {
        ...state,
        loading: true,
      }
    },
    setAgencyInvoices(state, { payload }) {
      return {
        ...state,
        loading: false,
        invoiceData: payload.data,
        invoiceTotal: payload.total,
      }
    },
    downloadInvoice(state) {
      return {
        ...state,
        loading: true,
        invoiceFile: null,
      }
    },
    setInvoice(state, { payload }) {
      return {
        ...state,
        loading: false,
        invoiceFile: payload,
      }
    },
  },
})

export const {
  getAgencyStats,
  setAgencyStats,
  getAgencyCreators,
  setAgencyCreators,
  getAgencyInvoice,
  setAgencyInvoices,
  downloadInvoice,
  setInvoice,
} = agencySlice.actions
export default agencySlice.reducer
